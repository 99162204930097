import {
  userRequest,
  userRequest1,
  getRequest,
} from "../RequestMethod/RequestMethod";
import {
  ADMIN_LOGIN_REQUEST,
  ADMIN_LOGIN_SUCCESS,
  ADMIN_LOGIN_FAIL,
  ADMIN_LOGOUT,
} from "../Contans/AdminContans";
import Password from "antd/es/input/Password";
import axios from "axios";
class API {
  constructor() {
    this.rootUrl = process.env.REACT_APP_API;
    this.company = process.env.REACT_APP_COMPANY_NAME;
    this.token = process.env.REACT_APP_COMPANY_NAME;
  }

  login = async (dispatch, data) => {
    try {
      dispatch({ type: ADMIN_LOGIN_REQUEST });

      const res = await getRequest.post("login.php", data);
      if (res.data.success === true) {
        dispatch({ type: ADMIN_LOGIN_SUCCESS, payload: res.data });
      } else {
        dispatch({ type: ADMIN_LOGIN_FAIL, payload: res.data });
      }
      return res;
    } catch (error) {
      return error;
      dispatch({
        type: ADMIN_LOGIN_FAIL,
        payload: error.response.data.message,
      });
      // return res;
    }
  };

  logout = async (dispatch) => {
    dispatch({ type: ADMIN_LOGOUT });
  };

  login1 = async (data,pass) => {    
    const params = {
      mobile: data,
      password: pass
    }
    try {      
      const res = await getRequest.post('login.php', params);      
      return res;
    } catch (error) {
      return error;        
    }
  };

  login2 = async (dispatch, data) => {
    const params = {
      mobile: data,
      // otp: otp,
    }
    try {
      dispatch({ type: ADMIN_LOGIN_REQUEST });
      const res = await getRequest.post("login.php", data);
      if (res.data.success === true) {
        dispatch({ type: ADMIN_LOGIN_SUCCESS, payload: res.data });
      } else {
        dispatch({ type: ADMIN_LOGIN_FAIL, payload: res.data });
      }
      return res;
    } catch (error) {
      return error;
      dispatch({
        type: ADMIN_LOGIN_FAIL,
        payload: error.response.data.message,
      });
      // return res;
    }
  };

  country = async () => {
    let data = [];
    data.push({
      modulename: "country",
      Token: this.token,
    });
    try {
      const res = await getRequest.post(`master.php`, data[0]);
      return res;
    } catch (error) {
      return error;
      // return res;
    }
  };

  //State
  getState = async () => {
    try {
      const res = await userRequest.get(`state.php`);

      return res;
    } catch (error) {
      return error;
    }
  };

  addstate = async (data) => {
    try {
      const res = await userRequest.post(`state.php`, data);

      return res;
    } catch (error) {
      return error;
    }
  };

  viewstate = async (data) => {
    // console.log(data)
    let data1 = {
      state_id: data,
    };

    try {
      const res = await userRequest.get(`state.php`, { params: data1 });
      return res;
    } catch (error) {
      return error;
    }
  };

  updatestate = async (stateId, data) => {
    try {
      const res = await userRequest.put(`state.php?state_id=${stateId}`, data);
      return res;
    } catch (error) {
      return error;
    }
  };

  delete = async (data) => {
    // console.log('dataaaaa', data)
    let data1 = {
      state_id: data,
    };
    try {
      const res = await userRequest.delete(`state.php`, { params: data1 });
      // console.log('resss', res)
      return res;
    } catch (error) {
      return error;
    }
  };

  //District

  getdistrict = async () => {
    try {
      const res = await userRequest.get(`district.php`);

      return res;
    } catch (error) {
      return error;
    }
  };

  getdistrictbasedonstate = async (stateId) => {
    try {
      const res = await userRequest.get(`district.php?state_id=${stateId}`);

      return res;
    } catch (error) {
      return error;
    }
  };

  adddistrict = async (data) => {
    try {
      const res = await userRequest.post(`district.php`, data);

      return res;
    } catch (error) {
      return error;
    }
  };

  statelist = async () => {
    try {
      const res = await userRequest.get(`state.php`);
      return res;
    } catch (error) {
      return error;
    }
  };

  viewdistrict = async (data) => {
    // console.log(data)
    let data1 = {
      district_id: data,
    };
    // console.log('data1', data1)

    try {
      const res = await userRequest.get(`district.php`, { params: data1 });

      return res;
    } catch (error) {
      return error;
    }
  };

  delete1 = async (id) => {
    try {
      const res = await userRequest.delete(`district.php?district_id=${id}`);

      return res;
    } catch (error) {
      return error;
    }
  };
  EditDistrict = async (id, formdata) => {
    // console.log('dataaaaa', data)
    // console.log("id", id)
    try {
      const res = await userRequest.put(
        `district.php?district_id=${id}`,
        formdata
      );
      // console.log('resss', res)
      return res;
    } catch (error) {
      return error;
    }
  };

  //Area
  getarea = async () => {
    try {
      const res = await userRequest.get(`area.php`);

      return res;
    } catch (error) {
      return error;
    }
  };

  getareabasedondistrict = async (districtId) => {
    try {
      const res = await userRequest.get(`area.php?district_id=${districtId}`);

      return res;
    } catch (error) {
      return error;
    }
  };

  addarea = async (data) => {
    try {
      const res = await userRequest.post(`area.php`, data);

      return res;
    } catch (error) {
      return error;
    }
  };

  statelist = async () => {
    try {
      const res = await userRequest.get(`state.php`);
      return res;
    } catch (error) {
      return error;
    }
  };

  districtlist = async () => {
    try {
      const res = await userRequest.get(`district.php`);
      return res;
    } catch (error) {
      return error;
    }
  };

  viewarea = async (data) => {
    // console.log(data)
    let data1 = {
      area_id: data,
    };
    // console.log('data1', data1)

    try {
      const res = await userRequest.get(`area.php`, { params: data1 });

      return res;
    } catch (error) {
      return error;
    }
  };

  deletearea = async (data) => {
    // console.log('dataaaaa', data)
    let data1 = {
      area_id: data,
    };
    try {
      const res = await userRequest.delete(`area.php`, { params: data1 });
      // console.log('resss', res)
      return res;
    } catch (error) {
      return error;
    }
  };

  editarea = async (id, formdata) => {
    // console.log('dataaaaa', data)
    // console.log("id", id)
    try {
      const res = await userRequest.put(`area.php?area_id=${id}`, formdata);
      // console.log('resss', res)
      return res;
    } catch (error) {
      return error;
    }
  };

  getbranch = async () => {
    try {
      const res = await userRequest.get(`branch.php`);

      return res;
    } catch (error) {
      return error;
    }
  };

  addbranch = async (data) => {
    try {
      const res = await userRequest.post(`branch.php`, data);

      return res;
    } catch (error) {
      return error;
    }
  };

  viewbranch = async (data) => {
    // console.log(data)
    let data1 = {
      branch_id: data,
    };
    // console.log('data1', data1)

    try {
      const res = await userRequest.get(`branch.php`, { params: data1 });
      // console.log("branch", res)
      return res;
    } catch (error) {
      return error;
    }
  };

  editbranch = async (id, formdata) => {
    // console.log('dataaaaa', data)
    // console.log("id", id)
    try {
      const res = await userRequest.put(`branch.php?branch_id=${id}`, formdata);
      // console.log('resss', res)
      return res;
    } catch (error) {
      return error;
    }
  };

  deletebranch = async (data) => {
    // console.log('dataaaaa', data)
    let data1 = {
      branch_id: data,
    };
    try {
      const res = await userRequest.delete(`branch.php`, { params: data1 });
      // console.log('resss', res)
      return res;
    } catch (error) {
      return error;
    }
  };

  Company = async () => {
    try {
      const res = await userRequest.get(`company.php`);

      return res;
    } catch (error) {
      return error;
    }
  };

  addcompany = async (data) => {
    try {
      const res = await userRequest.post(`company.php`, data);

      return res;
    } catch (error) {
      return error;
    }
  };

  retrievecompany = async (data) => {
    // console.log(data)
    let data1 = {
      company_id: data,
    };
    // console.log('data1', data1)
    try {
      const res = await userRequest.post(`company.php`, data1);

      return res;
    } catch (error) {
      return error;
    }
  };

  viewcompany = async () => {
    try {
      const res = await userRequest.get(`company.php`);

      return res;
    } catch (error) {
      return error;
    }
  };

  editcompany = async (id, formdata) => {
    // console.log('dataaaaa', data)
    // console.log("id", id)
    try {
      const res = await userRequest.put(
        `company.php?company_id=${id}`,
        formdata
      );
      // console.log('resss', res)
      return res;
    } catch (error) {
      return error;
    }
  };

  getdemurrage = async () => {
    try {
      const res = await userRequest.get(`demurrage.php`);

      return res;
    } catch (error) {
      return error;
    }
  };

  adddemurrage = async (data) => {
    try {
      const res = await userRequest.post(`demurrage.php`, data);

      return res;
    } catch (error) {
      return error;
    }
  };
  editdemurrage = async (id, formdata) => {
    try {
      const res = await userRequest.put(
        `demurrage.php?demurrage_id=${id}`,
        formdata
      );
      // console.log('resss', res)
      return res;
    } catch (error) {
      return error;
    }
  };

  viewdemurrage = async (data) => {
    // console.log(data)
    let data1 = {
      demurrage_id: data,
    };
    // console.log('data1', data1)
    try {
      const res = await userRequest.get(`demurrage.php/demurrage_id=${data}`);
      // console.log("ssss", res)

      return res;
    } catch (error) {
      return error;
    }
  };

  deletedemurrage = async (data) => {
    // console.log('dataaaaa', data)
    let data1 = {
      demurrage_id: data,
    };
    try {
      const res = await userRequest.delete(`demurrage.php`, { params: data1 });
      // console.log('resss', res)
      return res;
    } catch (error) {
      return error;
    }
  };

  //Role
  getRole = async () => {
    try {
      const res = await userRequest.get(`role.php`);

      return res;
    } catch (error) {
      return error;
    }
  };

  addrole = async (data) => {
    try {
      const res = await userRequest.post(`role.php`, data);

      return res;
    } catch (error) {
      return error;
    }
  };

  viewrole = async (roleId) => {
    try {
      const res = await userRequest.get(
        `role.php?role_id=${roleId}&mode=retrieve`
      );
      return res;
    } catch (error) {
      return error;
    }
  };

  editrole = async (roleId, formData) => {
    try {
      const res = await userRequest.put(`role.php?role_id=${roleId}`, formData);
      return res;
    } catch (error) {
      return error;
    }
  };

  editbranch = async (id, formdata) => {
    // console.log('dataaaaa', data)
    // console.log("id", id)
    try {
      const res = await userRequest.put(`branch.php?branch_id=${id}`, formdata);
      // console.log('resss', res)
      return res;
    } catch (error) {
      return error;
    }
  };

  deleterole = async (data) => {
    // console.log('dataaaaa', data)
    let data1 = {
      role_id: data,
    };
    try {
      const res = await userRequest.delete(`role.php`, { params: data1 });
      // console.log('resss', res)
      return res;
    } catch (error) {
      return error;
    }
  };

  //Branch
  getBranchList = async () => {
    try {
      const res = await userRequest.get(`branch.php`);
      return res;
    } catch (error) {
      return error;
    }
  };

  //Voucher option (branch day booking)
  getFilterList = async () => {
    try {
      const res = await userRequest.get(`voucher-option-list.php`);
      return res;
    } catch (error) {
      return error;
    }
  };

  //Ledger
  getLedgerList = async () => {
    try {
      const res = await userRequest.get(`ledger.php`);

      return res;
    } catch (error) {
      return error;
    }
  };

  //reset password
  getpassword = async () => {
    try {
      const res = await userRequest.get(`reset-password.php`);
      return res;
    } catch (error) {
      const res = error.response;
    }
  };

  updatePassword = async (data) => {
    try {
      const res = await userRequest.post(`reset-password.php`, data);
      return res;
    } catch (error) {
      const res = error.response;
    }
  };
  updatePassworduser = async (data) => {
    try {
      const res = await userRequest.post(`reset-password.php`, data);
      return res;
    } catch (error) {
      const res = error.response;
    }
  };

  //User
  getUser = async () => {
    try {
      const res = await userRequest.get(`user.php`);

      return res;
    } catch (error) {
      return error;
    }
  };

  adduser = async (data) => {
    try {
      const res = await userRequest.post(`user.php`, data);

      return res;
    } catch (error) {
      return error;
    }
  };

  getSingleUser = async (userId) => {
    try {
      const res = await userRequest.get(
        `user.php?user_id=${userId}&mode=retrieve`
      );
      return res;
    } catch (error) {
      return error;
    }
  };

  updateUser = async (userId, data) => {
    try {
      const res = await userRequest.post(`user.php?user_id=${userId}`, data);

      return res;
    } catch (error) {
      return error;
    }
  };

  deleteuser = async (userId) => {
    try {
      const res = await userRequest.delete(`user.php?user_id=${userId}`);
      return res;
    } catch (error) {
      return error;
    }
  };

  getVehicle = async () => {
    try {
      const res = await userRequest.get(`vehicle_type.php`);

      return res;
    } catch (error) {
      return error;
    }
  };
  getVehicle_status = async () => {
    try {
      const res = await userRequest.get(`vehicle_type.php?status=1`);

      return res;
    } catch (error) {
      return error;
    }
  };

  addVehicle = async (data) => {
    try {
      const res = await userRequest.post(`vehicle_type.php`, data);

      return res;
    } catch (error) {
      return error;
    }
  };

  viewvehicletype = async (vehicleId) => {
    try {
      const res = await userRequest.get(
        `vehicle_type.php?vehicle_type_id=${vehicleId}&mode=retrieve`
      );
      return res;
    } catch (error) {
      return error;
    }
  };

  editvehicletype = async (vehicleTypeId, formData) => {
    try {
      const res = await userRequest.put(
        `vehicle_type.php?vehicle_type_id=${vehicleTypeId}`,
        formData
      );
      return res;
    } catch (error) {
      return error;
    }
  };

  deleteVehicle = async (data) => {
    // console.log('dataaaaa', data)
    let data1 = {
      vehicle_type_id: data,
    };
    try {
      const res = await userRequest.delete(`vehicle_type.php`, { params: data1 });
      // console.log('resss', res)
      return res;
    } catch (error) {
      return error;
    }
  };

  //Maintenance Master Dropdown

  getmaintenancedropdown = async () => {
    try {
      const res = await userRequest.get(`maintenance_master.php?status=1`);
      return res;
    } catch (error) {
      return error;
    }
  };

  updatemaintenance = async (Id, data) => {
 
    try {
      const res = await userRequest.put(`maintenance.php?id=${Id}`, data);
      return res;
    } catch (error) {
      return error;
    }
  };

  //Manitanence Master

  getmaintanence = async () => {
    try {
      const res = await userRequest.get(`maintenance_master.php`);

      return res;
    } catch (error) {
      return error;
    }
  };

  addmaintanence = async (data) => {
    try {
      const res = await userRequest.post(`maintenance_master.php`, data);

      return res;
    } catch (error) {
      return error;
    }
  };

  viewmaintanence = async (data, data2) => {
    // console.log(data)
    let data1 = {
      maintenance_id: data,
    };
    // console.log('data1', data1)

    try {
      const res = await userRequest.get(
        `maintenance_master.php?maintenance_id=${data}`
      );

      return res;
    } catch (error) {
      return error;
    }
  };

  deletemaintanence = async (data) => {
    // console.log('dataaaaa', data)
    let data1 = {
      maintenance_id: data,
    };
    try {
      const res = await userRequest.delete(`maintenance_master.php`, {
        params: data1,
      });
      // console.log('resss', res)
      return res;
    } catch (error) {
      return error;
    }
  };

  viewMaintenance = async (maintenanceId) => {
    try {
      const res = await userRequest.get(
        `maintenance_master.php?maintenance_id=${maintenanceId}&mode=retrieve`
      );
      return res;
    } catch (error) {
      return error;
    }
  };

  editmaintenance = async (maintenanceId, formData) => {
    try {
      const res = await userRequest.put(
        `maintenance_master.php?maintenance_id=${maintenanceId}`,
        formData
      );
      return res;
    } catch (error) {
      return error;
    }
  };

  //Vehicle
  getvehicle = async () => {
    try {
      const res = await userRequest.get(`vehicle_master.php`);

      return res;
    } catch (error) {
      return error;
    }
  };

  addvehicle = async (data) => {
    try {
      const res = await userRequest.post(`vehicle_master.php`, data);

      return res;
    } catch (error) {
      return error;
    }
  };

  

  viewvehicle = async (data) => {
    // console.log(data)
    let data1 = {
      vehicle_id: data,
      mode: "retrieve",
    };
    // console.log('data1', data1)

    try {
      const res = await userRequest.get(
        `vehicle_master.php?vehicle_id=${data}&mode=retrieve`
      );

      return res;
    } catch (error) {
      return error;
    }
  };

  deletevehicle = async (data) => {
    // console.log('dataaaaa', data)
    let data1 = {
      vehicle_id: data,
    };
    try {
      const res = await userRequest.delete(`vehicle_master.php`, {
        params: data1,
      });
      // console.log('resss', res)
      return res;
    } catch (error) {
      return error;
    }
  };

  //getVehicle-type
  getvehicletype = async () => {
    try {
      const res = await userRequest.get(`vehicle_type.php`);
      return res;
    } catch (error) {
      return error;
    }
  };

  addvehicletype = async (data) => {
    try {
      const res = await userRequest.post(`vehicle_type.php`, data);

      return res;
    } catch (error) {
      return error;
    }
  };

  editvehicle = async (id, formdata) => {
    // console.log('dataaaaa', data)
    // console.log("id", id)
    console.log("formdata", formdata)
    try {
      const res = await userRequest.put(
        `vehicle_master.php?vehicle_id=${id}`,
        formdata
      );
      // console.log('resss', res)
      return res;
    } catch (error) {
      return error;
    }
  };

  //Customer/Vendor
  getparty = async () => {
    try {
      const res = await userRequest.get(`party.php`);
      return res;
    } catch (error) {
      return error;
    }
  };

  addparty = async (data) => {
    try {
      const res = await userRequest.post(`party.php`, data);

      return res;
    } catch (error) {
      return error;
    }
  };

  deleteparty = async (data) => {
    // console.log('dataaaaa', data)
    let data1 = {
      party_id: data,
    };
    try {
      const res = await userRequest.delete(`vehicle_master.php`, {
        params: data1,
      });
      // console.log('resss', res)
      return res;
    } catch (error) {
      return error;
    }
  };

  viewparty = async (partyId) => {
    try {
      const res = await userRequest.get(
        `party.php?party_id=${partyId}&mode=retrieve`
      );
      return res;
    } catch (error) {
      return error;
    }
  };

  editparty = async (partyId, formData) => {
    try {
      const res = await userRequest.put(
        `party.php?party_id=${partyId}`,
        formData
      );
      return res;
    } catch (error) {
      return error;
    }
  };

  deleteParty = async (partyId) => {
    try {
      const res = await userRequest.delete(`party.php?party_id=${partyId}`);
      return res;
    } catch (error) {
      return error;
    }
  };

  //Category
  getcategory = async () => {
    try {
      const res = await userRequest.get(`product_category.php`);
      return res;
    } catch (error) {
      return error;
    }
  };

  addcategory = async (data) => {
    try {
      const res = await userRequest.post(`product_category.php`, data);

      return res;
    } catch (error) {
      return error;
    }
  };

  viewcategory = async (categoryId) => {
    try {
      const res = await userRequest.get(
        `product_category.php?category_id=${categoryId}`
      );

      return res;
    } catch (error) {
      return error;
    }
  };

  editcategory = async (formData) => {
    try {
      const res = await userRequest.put(`product_category.php`, formData);
      return res;
    } catch (error) {
      return error;
    }
  };

  deletecategory = async (data) => {
    // console.log("category", data)
    let data1 = {
      category_id: data,
    };
    try {
      const res = await userRequest.delete(`product_category.php`, {
        params: data1,
      });
      // console.log('resss', res)
      return res;
    } catch (error) {
      return error;
    }
  };

  //GST
  gettax = async () => {
    try {
      const res = await userRequest.get(`tax.php`);

      return res;
    } catch (error) {
      return error;
    }
  };

  addtax = async (data) => {
    try {
      const res = await userRequest.post(`tax.php`, data);

      return res;
    } catch (error) {
      return error;
    }
  };

  viewtax = async (taxId) => {
    try {
      const res = await userRequest.get(
        `tax.php?tax_id=${taxId}&mode=retrieve`
      );
      return res;
    } catch (error) {
      return error;
    }
  };

  edittax = async (id, formdata) => {
    try {
      const res = await userRequest.put(`tax.php?tax_id=${id}`, formdata);
      // console.log('resss', res)
      return res;
    } catch (error) {
      return error;
    }
  };

  deletetax = async (data) => {
    // console.log('dataaaaa', data)
    let data1 = {
      tax_id: data,
    };
    try {
      const res = await userRequest.delete(`tax.php`, { params: data1 });
      // console.log('resss', res)
      return res;
    } catch (error) {
      return error;
    }
  };

  // Vehicle Document

  getvehicledocument = async () => {
    try {
      const res = await userRequest.get(`alert-master.php`);

      return res;
    } catch (error) {
      return error;
    }
  };

  addvehicledocument = async (data) => {
    try {
      const res = await userRequest.post(`alert-master.php`, data);

      return res;
    } catch (error) {
      return error;
    }
  };

  addvehicledocs = async (data) => {
    try {
      const res = await userRequest.post(`vehicle-document.php`, 
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data', // Set the content type for file upload
        },
      }
      );

      return res;
    } catch (error) {
      return error;
    }
  }
  
  deletevehicledocs = async (id) => {
    try {
      const res = await userRequest.delete(`vehicle-document.php?id=${id}`
      // {
      //   headers: {
      //     'Content-Type': 'multipart/form-data', 
      //   },
      // }
      );

      return res;
    } catch (error) {
      return error;
    }
  }

  downloadvehicledocs = async(url)=>{
    try {
      const res = await userRequest.get(url);
      return res;
    } catch (error) {
      return error;
    }
  }

  getvehicledocs = async (vehicleId) => {
    try {
      const res = await userRequest.get(`vehicle-document.php?vehicle_id=${vehicleId}`);

      return res;
    } catch (error) {
      return error;
    }
  }

  viewvehicledocument = async (data) => {
    // console.log(data)
    let data1 = {
      alert_id: data,
      mode: "retrieve",
    };
    // console.log('data1', data1)

    try {
      const res = await userRequest.get(
        `alert-master.php?alert_id=${data}&mode=retrieve`
      );
      // console.log("Tax", res)
      return res;
    } catch (error) {
      return error;
    }
  };

  editvechicledocument = async (alertId, formData) => {
    try {
      const res = await userRequest.put(
        `alert-master.php?alert_id=${alertId}`,
        formData
      );
      return res;
    } catch (error) {
      return error;
    }
  };

  deletedocument = async (data) => {
    try {
      const res = await userRequest.delete(`alert-master.php?id=${data}`);
      return res;
    } catch (error) {
      return error;
    }
  };

  //Product
  getproduct = async () => {
    try {
      const res = await userRequest.get(`product.php`);

      return res;
    } catch (error) {
      return error;
    }
  };
  getpurchaseproducttype = async () => {  
    try {
      const res = await userRequest.get(`product.php?product_type=1&status=1`);
      return res;
    } catch (error) {
      return error;
    }
  };


  addproduct = async (data) => {
    try {
      const res = await userRequest.post(`product.php`, data);

      return res;
    } catch (error) {
      return error;
    }
  };

  viewproduct = async (data) => {
    // console.log(data)
    let data1 = {
      product_id: data,
    };
    // console.log('data1', data1)

    try {
      const res = await userRequest.get(`product.php`, { params: data1 });
      // console.log("Tax", res)
      return res;
    } catch (error) {
      return error;
    }
  };

  editproduct = async (formData) => {
    // console.log('dataaaaa', data)
    try {
      const res = await userRequest.put(`product.php`, formData);
      // console.log('resss', res)
      return res;
    } catch (error) {
      return error;
    }
  };

  deleteproduct = async (data) => {
    // console.log('dataaaaa', data)
    let data1 = {
      product_id: data,
    };
    try {
      const res = await userRequest.delete(`product.php`, { params: data1 });
      // console.log('resss', res)
      return res;
    } catch (error) {
      return error;
    }
  };

  //Purchase Product
  getpurchase = async () => {
    try {
      const res = await userRequest.get(`purchase_product.php`);

      return res;
    } catch (error) {
      return error;
    }
  };

  getpurchase1 = async (id) => {
    try {
      const res = await userRequest.get(`purchase_product.php?id=${id}`);

      return res;
    } catch (error) {
      return error;
    }
  };



  addpurchaseproducts = async (data) => {
    try {
      const res = await userRequest.post(`purchase_product.php`, data);
      return res;
    } catch (error) {
      return error;
    }
  };

  getpurchaseproducts = async () => {
    try {
      const res = await userRequest.get(`product.php?product_type=1&status=1`);
      return res;
    } catch (error) {
      return error;
    }
  };

  viewpurchase = async (data) => {
    // console.log(data)
    let data1 = {
      id: data,
    };
    // console.log('data1', data1)

    try {
      const res = await userRequest.get(`purchase_product.php`, {
        params: data1,
      });
      // console.log("Tax", res)
      return res;
    } catch (error) {
      return error;
    }
  };

  editpurchase = async (id, formdata) => {
    // console.log('dataaaaa', data)
    // console.log("id", id)
    try {
      const res = await userRequest.put(
        `purchase_product.php`,
        formdata
      );
      // console.log('resss', res)
      return res;
    } catch (error) {
      return error;
    }
  };

  deletepurchase = async (data) => {
    // console.log('dataaaaa', data)
    let data1 = {
      id: data,
    };
    try {
      const res = await userRequest.delete(`purchase_product.php`, {
        params: data1,
      });
      // console.log('resss', res)
      return res;
    } catch (error) {
      return error;
    }
  };

  //Attachment
  getattachment = async () => {
    try {
      const res = await userRequest.get(`user-attachment-master.php`);

      return res;
    } catch (error) {
      return error;
    }
  };

  addattachment = async (data) => {
    try {
      const res = await userRequest.post(`user-attachment-master.php`, data);

      return res;
    } catch (error) {
      return error;
    }
  };

  viewattachment = async (data) => {
    // console.log(data)
    let data1 = {
      attachment_id: data,
      mode: "retrieve",
    };
    // console.log('data1', data1)

    try {
      const res = await userRequest.get(
        `user-attachment-master.php?attachment_id=${data}&mode=retrieve`
      );
      // console.log("Tax", res)
      return res;
    } catch (error) {
      return error;
    }
  };

  editattachment = async (attachmentId, formData) => {
    try {
      const res = await userRequest.put(
        `user-attachment-master.php?attachment_id=${attachmentId}`,
        formData
      );
      return res;
    } catch (error) {
      return error;
    }
  };

  viewVehicleAttachment =async () => {
    try{
      const res = await userRequest.get(`alert-master.php?status=1`);
      return res;
    } catch(err) {
      return err;
    }
    
  }

  addVehicleAttachment = (data) => {
    try {
      const res = userRequest.post(`vehicle-document.php`, data);
      return res;
    } catch(err) {
     return err;
    }
  }

  deleteattachment = async (data) => {
    // console.log('dataaaaa', data)
    let data1 = {
      attachment_id: data,
    };
    try {
      const res = await userRequest.delete(`user-attachment-master.php`, {
        params: data1,
      });
      // console.log('resss', res)
      return res;
    } catch (error) {
      return error;
    }
  };

  //Group
  getgroup = async () => {
    try {
      const res = await userRequest.get(`group.php`);

      return res;
    } catch (error) {
      return error;
    }
  };

  addgroup = async (data) => {
    try {
      const res = await userRequest.post(`group.php`, data);

      return res;
    } catch (error) {
      return error;
    }
  };

  viewgroup = async (data) => {
    let data1 = {
      accounts_group_id: data,
    };

    try {
      const res = await userRequest.get(`group.php`, { params: data1 });
      // console.log("Tax", res)
      return res;
    } catch (error) {
      return error;
    }
  };

  editgroup = async (id, formdata) => {
    try {
      const res = await userRequest.put(
        `group.php?accounts_group_id=${id}`,
        formdata
      );
      // console.log('resss', res)
      return res;
    } catch (error) {
      return error;
    }
  };

  deletegroup = async (data) => {
    // console.log('dataaaaa', data)
    let data1 = {
      accounts_group_id: data,
    };
    try {
      const res = await userRequest.delete(`group.php`, { params: data1 });
      // console.log('resss', res)
      return res;
    } catch (error) {
      return error;
    }
  };

  //Ledger
  getledger = async () => {
    try {
      const res = await userRequest.get(`ledger.php`);

      return res;
    } catch (error) {
      return error;
    }
  };

  addledger = async (data) => {
    try {
      const res = await userRequest.post(`ledger.php`, data);

      return res;
    } catch (error) {
      return error;
    }
  };

  viewledger = async (data) => {
    // console.log(data)
    let data1 = {
      accounts_group_id: data,
      mode: "retrieve",
    };
    // console.log('data1', data1)

    try {
      const res = await userRequest.get(
        `ledger.php?accounts_group_id=${data}&mode=retrieve`
      );
      // console.log("Tax", res)
      return res;
    } catch (error) {
      return error;
    }
  };

  editledger = async (accountsId, formData) => {
    try {
      const res = await userRequest.put(
        `ledger.php?accounts_group_id=${accountsId}`,
        formData
      );
      return res;
    } catch (error) {
      return error;
    }
  };

  deleteledger = async (data) => {
    // console.log('dataaaaa', data)
    let data1 = {
      accounts_group_id: data,
    };
    try {
      const res = await userRequest.delete(`ledger.php`, { params: data1 });
      // console.log('resss', res)
      return res;
    } catch (error) {
      return error;
    }
  };

  //Print
  getprint = async (data) => {
    let data1 = {
      voucher_id: data,
    };

    try {
      const res = await userRequest.get(
        `print/payment-print.php?voucher_id=${data}`
      );

      return res;
    } catch (error) {
      return error;
    }
  };

  thermalprint = async (params) => {
    try {
      const res = await userRequest.get(
        `print/lr-thermal-print.php`, { params }
      );

      return res;
    } catch (error) {
      return error;
    }
  }


  getreceipt = async (voucherNo, fromDate, toDate, size, page) => {
    const params = {
      voucher_no: voucherNo,
      from_date: fromDate,
      to_date: toDate,
      length: size,
      page: page,
    };

    Object.keys(params).forEach(
      (key) => params[key] === undefined || params[key] === "" && delete params[key]
    );

    try {
      const res = await userRequest.get("receipt.php", { params });
      return res;
    } catch (error) {
      return error;
    }
  };


  getchildledger = async (cash, bank) => {
    //   let groupName = ['cash in hand', 'bank accounts'];
    // let encodedGroupName = groupName.map(name => encodeURIComponent(name));
    // let url = `getChildLedgers.php?group_name%5B%5D=${encodedGroupName[0]}&group_name%5B%5D=${encodedGroupName[1]}`;

    try {
      const res =
        await userRequest.get(`getChildLedgers.php?group_name%5B%5D=${cash}hand&group_name%5B%5D=${bank}%20accounts
    `);

      return res;
    } catch (error) {
      return error;
    }
  };

  addreceipt = async (data) => {
    // console.log('add', data)

    try {
      const res = await userRequest.post(`receipt.php`, data);

      return res;
    } catch (error) {
      return error;
    }
  };

  viewreceipt = async (data) => {
    // console.log(data)
    let data1 = {
      voucher_id: data,
      mode: "retrieve",
    };
    // console.log('data1', data1)

    try {
      const res = await userRequest.get(
        `receipt.php?voucher_id=${data}&mode=retrieve`
      );
      // console.log("receipt", res)
      return res;
    } catch (error) {
      return error;
    }
  };

  printreceipt = async (receiptId) => {
    try {
      const res = await userRequest.get(
        `print/receipt-print.php?voucher_id=${receiptId}`
      );
      console.log("receipt", res);
      return res;
    } catch (error) {
      const res = error.response;
    }
  };

  editreceipt = async (voucherId, formdata) => {
    console.log("formdata", formdata);
    try {
      const res = await userRequest.put(
        `receipt.php?voucher_id=${voucherId}`,
        formdata
      );
      return res;
    } catch (error) {
      return error;
    }
  };

  deletereceipt = async (data) => {
    let data1 = {
      voucher_id: data,
    };

    try {
      const res = await userRequest.delete(`receipt.php`, { params: data1 });
      return res;
    } catch (error) {
      return error;
    }
  };

  getpayment = async (voucherNo, fromDate, toDate, size, pageAsNumber) => {
    const params = {
      voucher_no: voucherNo,
      from_date: fromDate,
      to_date: toDate,
      length: size,
      page: pageAsNumber,
    };

    Object.keys(params).forEach(
      (key) => params[key] === undefined && delete params[key]
    );

    try {
      const res = await userRequest.get("payment.php", { params });
      return res;
    } catch (error) {
      return error;
    }
  };


  printpayment = async (receiptId) => {
    try {
      const res = await userRequest.get(
        `print/payment-print.php?voucher_id=${receiptId}`
      );
      // console.log("receipt", res);
      return res;
    } catch (error) {
      const res = error.response;
    }
  };

  //Schedule Multiple
  addschedulemultiple = async (data) => {
    try {
      const res = await userRequest.post(`schedule-multiple.php`, data);
      return res;
    } catch (error) {
      console.log(error);
    }
  };

  getschedulemultiple = async (vehicleNo, date) => {
    if (vehicleNo == null) {
      vehicleNo = "";
    }

    try {
      const res = await userRequest.get(
        `schedule_multiple.php?vehicle_no=${vehicleNo}&date=${date}`
      );
      return res;
    } catch (error) {
      console.log(error);
    }
  };

  viewmaintenance = async (Id) => {
    try {
      const res = await userRequest.get(
        `maintenance.php?id=${Id}&mode=retrieve`
      );
      return res;
    } catch (err) {
      return err;
    }
  };

  //get schedule dispatch list
  getscheduledispatchlist = async (senderBranchId, receiverBranchId) => {
const params = {}
if(senderBranchId) params.branch_id = senderBranchId
if(receiverBranchId) params.receiver_branch = receiverBranchId
    try {
      const res = userRequest.get(
        `schedule-dispatch.php`, {params}
      );
      return res;
    } catch (error) {
      return error;
    }
  };

  //DashBoard

  getlicenseexpiry = async () => {
    try {
      const res = await userRequest.get(`alert.php`);
      return res;
    } catch (err) {
      return err;
    }
  };

  gettouryearly = async (date) => {
    const params = {
      date,
    };

    Object.keys(params).forEach(
      (key) => params[key] == undefined || null || ("" && delete params[key])
    );
    try {
      const res = await userRequest.get(
        `dashboard/tour-yearly-statistics.php`,
        { params }
      );
      return res;
    } catch (err) {
      return err;
    }
  };

  getticketyearly = async (date) => {
    const params = {
      date,
    };

    Object.keys(params).forEach(
      (key) => params[key] == undefined || null || ("" && delete params[key])
    );
    try {
      const res = await userRequest.get(
        `dashboard/ticket-yearly-statistics.php`,
        { params }
      );
      return res;
    } catch (err) {
      return err;
    }
  };

  getluggageyearly = async (date) => {
    const params = {
      date,
    };

    Object.keys(params).forEach(
      (key) => params[key] == undefined || null || ("" && delete params[key])
    );
    try {
      const res = await userRequest.get(
        `dashboard/luggage-yearly-statistics.php`,
        { params }
      );
      return res;
    } catch (err) {
      return err;
    }
  };

  //get active schedules

  getactiveschedulelistdispatch = async () => {
    try {
      const res = userRequest.get(`schedule-active.php`);
      return res;
    } catch (error) {
      return error;
    }
  };

  //get active schedules

  getactiveschedulelist = async (
    vehicleNo,
    boardingBranchId,
    depatureBranchId,
    scheduleStatus
  ) => {
    const params = {
      vehicle_no: vehicleNo,
      boarding_branch: boardingBranchId,
      departure_branch: depatureBranchId,
      schedule_status: scheduleStatus,      
    };

    Object.keys(params).forEach(
      (key) => params[key] === undefined || params[key] === "" && delete params[key]
    );

    try {
      const res = userRequest.get(
        `schedule-active.php`, {params}
      );
      return res;
    } catch (error) {
      return error;
    }
  };

  //get active schedule list

  getactiveschedulelistluggage = async () => {
    try {
      const res = userRequest.get(`schedule-active.php?schedule_status=Start`);
      return res;
    } catch (error) {
      return error;
    }
  };

  //schedule Closing

  getscheduleclosingactive = (Id) => {
    try {
      const res = userRequest.get(`schedule-active.php?id=${Id}&mode=retrieve`);
      return res;
    } catch (error) {
      return error;
    }
  };

  //printing active schedule
  printactiveschedule = async (scheduleId) => {
    try {
      const res = userRequest.get(
        `print/dispatch-print.php?schedule_id=${scheduleId}`
      );
      return res;
    } catch (error) {
      return error;
    }
  };
  printactivescheduleunload = async (scheduleId) => {
    try {
      const res = userRequest.get(
        `print/unload-print.php?schedule_id=${scheduleId}`
      );
      return res;
    } catch (error) {
      return error;
    }
  };

  getchildledger = async (cash, bank) => {
    //   let groupName = ['cash in hand', 'bank accounts'];
    // let encodedGroupName = groupName.map(name => encodeURIComponent(name));
    // let url = `getChildLedgers.php?group_name%5B%5D=${encodedGroupName[0]}&group_name%5B%5D=${encodedGroupName[1]}`;

    try {
      const res =
        await userRequest.get(`getChildLedgers.php?group_name%5B%5D=${cash}hand&group_name%5B%5D=${bank}%20accounts
    `);

      return res;
    } catch (error) {
      return error;
    }
  };

  addpayment = async (data) => {
    // console.log('add', data)

    try {
      const res = await userRequest.post(`payment.php`, data);

      return res;
    } catch (error) {
      return error;
    }
  };

  viewpayment = async (voucherId) => {
    try {
      const res = await userRequest.get(
        `payment.php?voucher_id=${voucherId}&mode=retrieve`
      );
      return res;
    } catch (error) {
      return error;
    }
  };

  editpayment = async (voucherId, formdata) => {
    let data1 = {
      voucher_id: voucherId,
    };
    // console.log('data', data1)
    try {
      const res = await userRequest.put(
        `payment.php?voucher_id=${voucherId}`,
        formdata
      );
      // console.log('resss', res);
      return res;
    } catch (error) {
      return error;
    }
  };

  deletepayment = async (data) => {
    let data1 = {
      voucher_id: data,
    };

    try {
      const res = await userRequest.delete(`payment.php`, { params: data1 });
      return res;
    } catch (error) {
      return error;
    }
  };

  getcontra = async (voucherNo, fromDate, toDate, size, pageAsNumber) => {
    const params = {
      voucher_no: voucherNo,
      from_date: fromDate,
      to_date: toDate,
      length: size,
      page: pageAsNumber,
    }

    Object.keys(params).forEach((key) => params[key] === undefined && delete params[key]);

    try {
      const res = await userRequest.get('contra.php', { params });
      return res;
    } catch (error) {
      return error
    }
  };

  printcontra = async (receiptId) => {
    try {
      const res = await userRequest.get(
        `print/contra-print.php?voucher_id=${receiptId}`
      );
      // console.log("receipt", res);
      return res;
    } catch (error) {
      const res = error.response;
    }
  };

  getchildledger = async (cash, bank) => {
    //   let groupName = ['cash in hand', 'bank accounts'];
    // let encodedGroupName = groupName.map(name => encodeURIComponent(name));
    // let url = `getChildLedgers.php?group_name%5B%5D=${encodedGroupName[0]}&group_name%5B%5D=${encodedGroupName[1]}`;

    try {
      const res =
        await userRequest.get(`getChildLedgers.php?group_name%5B%5D=${cash}hand&group_name%5B%5D=${bank}%20accounts
    `);

      return res;
    } catch (error) {
      return error;
    }
  };

  getvoucherno = async (date, voucher_no) => {
    try {
      const res = await userRequest.get(
        `contra.php?date=${date}&mode=voucher_no`
      );
      return res;
    } catch (error) {
      return error;
    }
  };

  addcontra = async (data) => {
    // console.log('add', data)

    try {
      const res = await userRequest.post(`contra.php`, data);
      return res;
    } catch (error) {
      return error;
    }
  };

  viewcontra = async (voucherId) => {
    try {
      const res = await userRequest.get(
        `contra.php?voucher_id=${voucherId}&mode=retrieve`
      );
      return res;
    } catch (error) {
      return error;
    }
  };

  editcontra = async (voucherId, formData) => {
    // console.log("voucher_id", voucherId);

    try {
      const res = await userRequest.put(`contra.php?voucher_id=${voucherId}`, formData)
      return res;
    } catch (error) {
      return error
    }
  }

  deletecontra = async (data) => {
    let data1 = {
      voucher_id: data,
    };

    try {
      const res = await userRequest.delete(`contra.php`, { params: data1 });
      return res;
    } catch (error) {
      return error;
    }
  };

  //Journal
  getjournal = async (voucherNo, fromDate, toDate, size, page) => {

    const params = {
      voucher_no: voucherNo,
      from_date: fromDate,
      to_date: toDate,
      length: size,
      page: page,
    }

    // if (voucherNo == null) {
    //   voucherNo = "";
    // }

    try {
      const res = await userRequest.get('journal.php', { params })
      return res;
    } catch (error) {
      return error
    }
  }

  getjournalprint = async (voucherId) => {
    try {
      const res = await userRequest.get(
        `print/journal-print.php?voucher_id=${voucherId}`
      );
      return res;
    } catch (error) {
      return error;
    }
  };

  addjournal = async (data) => {
    try {
      const res = await userRequest.post(`journal.php`, data);
      return res;
    } catch (error) {
      return error;
    }
  };

  viewjournal = async (voucherId) => {
    try {
      const res = await userRequest.get(
        `journal.php?voucher_id=${voucherId}&mode=retrieve`
      );
      return res;
    } catch (error) {
      return error;
    }
  };

  updatejournal = async (voucherId, data) => {
    try {
      const res = await userRequest.put(
        `journal.php?voucher_id=${voucherId}`,
        data
      );
      return res;
    } catch (error) {
      return error;
    }
  };

  //luggagereport
  getLuggageReport = async (fromDate, toDate, bookingBranch, VehicleNo, freeze, isClosed, isCollected) => {
    const params = {
      from_date: fromDate,
      to_date: toDate,
      booking_branch: bookingBranch,
      vehicle_no: VehicleNo,
      freeze: freeze,
      is_closed: isClosed,
      is_collected: isCollected
    };
    Object.keys(params).forEach(
      (key) => params[key] === undefined || params[key] === "" && delete params[key]
    );
    try {
      const res = await userRequest.get(
        'report/luggage.php', { params }
      );
      return res;
    } catch (error) {
      return error;
    }
  };
  //Ledger Report
  getledgerreport = async (ledgerId, fromDate, toDate) => {

    const params = {
      ledger_id: ledgerId,
      from_date: fromDate,
      to_date: toDate,
    }

    try {
      const res = await userRequest.get('report/ledger_report.php', { params });
      return res;
    } catch (error) {
      return error
    }
  };


  printledgerreport = async (ledgerId, fromDate, toDate) => {
    try {
      const res = await userRequest.get(
        `print/ledger-print.php?ledger_id=${ledgerId}&from_date=${fromDate}&to_date=${toDate}`
      );
      return res;
    } catch (error) {
      const res = error.response;
    }
  };

  //Sedule Route
  getroute = async () => {
    try {
      const res = await userRequest.get(`route.php`);

      return res;
    } catch (error) {
      return error;
    }
  };

  addroute = async (data) => {
    try {
      const res = await userRequest.post(`route.php`, data);

      return res;
    } catch (error) {
      return error;
    }
  };

  viewroute = async (routeId) => {
    try {
      const res = await userRequest.get(
        `route.php?route_id=${routeId}&mode=retrieve`
      );
      return res;
    } catch (error) {
      return error;
    }
  };

  editroute = async (id, formdata) => {
    // console.log('dataaaaa', data)
    // console.log("id", id)
    try {
      const res = await userRequest.put(`route.php?route_id=${id}`, formdata);
      // console.log('resss', res)
      return res;
    } catch (error) {
      return error;
    }
  };

  deleteroute = async (routeId) => {
    try {
      const res = await userRequest.delete(`route.php?route_id=${routeId}`);
      return res;
    } catch (error) {
      return error;
    }
  };

  //luggage Demurrage
  getdemurrageluggage = async (
    BranchId,
    LrNo,
    FromDate,
    ToDate,
    ScheduleNo
  ) => {
    const params = {}
    if (BranchId) params.branch_id = BranchId
    if (LrNo) params.lr_no = LrNo
    if (FromDate) params.from_date = FromDate
    if (ToDate) params.to_date = ToDate
    if (ScheduleNo) params.schedule_no = ScheduleNo
    try {
      const res = await userRequest.get(
        `report/demurrage.php`, { params }
      );

      return res;
    } catch (error) {
      return error;
    }
  };

  getdemurragereport = async () => {
    try {
      const res = await userRequest.get(`report/demurrage.php`);
      return res;
    } catch (error) {
      return error;
    }
  };

  //luggage Cancelled

  getcommonluggageprimary = async () => {
    try {
      const res = await userRequest.get(`common/luggage-primary.php`);
      return res;
    } catch (error) {
      return error;
    }
  };

  //Report

  //Purchase Report

  getpurchasereport = async (fromDate, toDate, partyName, branchId, billNo) => {
    const params = {
      from_date: fromDate,
      to_date: toDate,
      party_name: partyName,
      branch_id: branchId,
      bill_no: billNo,
    };

    Object.keys(params).forEach(
      (key) => params[key] === undefined || params[key] === "" && delete params[key]
    );

    try {
      const res = await userRequest.get(
        'report/purchase.php', { params }
      );

      return res;
    } catch (error) {
      return error;
    }
  };

  //to get expense and income report  from only local host not from the actual api once the api is working properly change to getmaintenancereport method
  getexpenseandincomereportmocking = async (
    fromDate,
    toDate,
    routeId,
    vehicleId,
    reportType
  ) => {
const params = {}
if(fromDate) params.from_date = fromDate
if(toDate) params.to_date = toDate
if(routeId) params.route_id = routeId
if(vehicleId) params.vehicle_id = vehicleId
if(reportType) params.type = reportType

    try {
      const res = await userRequest.get(
        `report/income-expense.php`,{params}
      );
      return res;
    } catch (error) { }
  };

  addexpenseandincome = async(data)=>{
    console.log("type of", typeof data)
    try {
      const res = await userRequest.post(
        'income-expense.php', data, 
        {
          headers: {
            'Content-Type': 'multipart/form-data', // Set the content type for file upload
          },
        }
      );
      return res;
    } catch (error) {
      return error;
    }
  }

  //get fuel report from local. mock data
  getfuelreportmocking = async (
    fromDate,
    toDate,
    routeId,
    vehicleId,
    primaryDriver,
    secondaryDriver
  ) => {
    const params = {
      from_date: fromDate,
      to_date: toDate,
      vehicle_id: vehicleId,
      route_id: routeId,
      primary_driver: primaryDriver,
      secondary_driver: secondaryDriver,
    };
    Object.keys(params).forEach(
      (key) => params[key] === undefined || params[key] === "" && delete params[key]
    );
    try {
      const res = await userRequest.get(
        'report/fuel.php', { params }
      );
      return res;
    } catch (error) { }
  };

  //this is a method to get the report from only local host not from the actual api once the api is working properly change to getmaintenancereport method
  getmaintenancereportmocking = async (vehicleId, MaintenanceId) => {
    const params = {
      vehicle_id: vehicleId,
      maintenance_id: MaintenanceId
    }

    Object.keys(params).forEach(
      (key) => params[key] === undefined || params[key] === "" && delete params[key]
    );

    try {
      const res = await userRequest.get(
        'report/maintenance.php', { params }
      );
      return res;
    } catch (error) {
      return error;
    }
  };

  //get schedule  reports from local mock data
  getschedulereportmocking = async (
    fromDate,
    toDate,
    boardingBranch,
    destinationBranch,
    vehicleNo,
    serviceNo,
    primaryDriver,
    secondaryDriver,
    status
  ) => {
    const params = {
      from_date: fromDate,
      to_date: toDate,
      boarding_branch: boardingBranch,
      departure_branch: destinationBranch,
      vehicle_no: vehicleNo,
      service_no: serviceNo,
      primary_driver: primaryDriver,
      secondary_driver: secondaryDriver,
      status: status,
    };

    Object.keys(params).forEach(
      (key) => params[key] === undefined || params[key] === "" && delete params[key]
    );

    try {
      const res = await userRequest.get(
        'report/schedule.php', { params }
      );

      return res;
    } catch (error) {
      return error;
    }

  }

  getcontra = async (voucherNo, fromDate, toDate, size, pageAsNumber) => {
    const params = {
      voucher_no: voucherNo,
      from_date: fromDate,
      to_date: toDate,
      length: size,
      page: pageAsNumber,
    }

    Object.keys(params).forEach((key) => params[key] === undefined && delete params[key]);

    try {
      const res = await userRequest.get('contra.php', { params });
      return res;
    } catch (error) {
      return error
    }
  };

  gettyredisposepending = async (branchId) => {
    try {
      const res = await userRequest.get(`tyre-dispose-pending.php?branch_id=${branchId}`)
      return res;
    } catch (err) {
      return err;
    }
  }

  //Tyre Summary

  gettyresummary = async (tyreSerialNo, vehicleNo) => {
    if (tyreSerialNo == null) {
      tyreSerialNo = "";
    }
    if (vehicleNo == null) {
      vehicleNo = "";
    }

    try {
      const res =
        await userRequest.get(`report/tyre_summary.php?tyre_serial_no=${tyreSerialNo}&vehicle_no=${vehicleNo}
   `);
      return res;
    } catch (error) {
      return error;
    }
  };

  //Maintenance
  getmaintenancereport = async (vehicleId, MaintenanceId) => {
    if (vehicleId == null) {
      vehicleId = "";
    }
    if (MaintenanceId == null) {
      MaintenanceId = "";
    }

    try {
      const res =
        await userRequest.get(`report/maintenance.php?vehicle_id=${vehicleId}&maintenance_id=${MaintenanceId}
   `);
      return res;
    } catch (error) {
      return error;
    }
  };

  //Driver / Cleaner

  getdriver = async () => {
    try {
      const res = await userRequest.get(`driver-cleaner.php`);
      return res;
    } catch (error) {
      return error;
    }
  };

  //Schedule Report

  getschedulereport = async (
    fromDate,
    toDate,
    boardingBranch,
    departureBranch,
    vehicleNo,
    serviceNo,
    primaryDriver,
    secondaryDriver,
    status
  ) => {
    try {
      const res =
        await userRequest.get(`report/schedule.php?from_date=${fromDate}&to_date=${toDate}&boarding_branch=${boardingBranch}
   &departure_branch=${departureBranch}&vehicle_no=${vehicleNo}&service_no=${serviceNo}&primary_driver=${primaryDriver}&secondary_driver=${secondaryDriver}&status=${status}
   `);
      return res;
    } catch (error) {
      return error;
    }
  };

  //Tour Report

  gettourreport = async (fromDate, toDate, bookingBranch, VehicleNo, freeze, isClosed, isCollected) => {
    const params = {
      from_date: fromDate,
      to_date: toDate,
      booking_branch: bookingBranch,
      vehicle_no: VehicleNo,
      freeze: freeze,
      is_closed: isClosed,
      is_collected: isCollected
    };
    Object.keys(params).forEach(
      (key) => params[key] === undefined || params[key] === "" && delete params[key]
    );
    try {
      const res = await userRequest.get(
        'report/tour.php', { params }
      );
      return res;
    } catch (error) {
      return error;
    }
  };

  //Credit Party
  getcreditparty = async (
    fromDate,
    toDate,
    lrNo,
    partyId,
    senderBranch,
    receiverBranch
  ) => {
    const params = {
      from_date: fromDate,
      to_date: toDate,
      lr_no: lrNo,
      party_id: partyId,
      sender_branch: senderBranch,
      receiver_branch: receiverBranch,
    };
    Object.keys(params).forEach(
      (key) => params[key] === undefined || params[key] === "" && delete params[key]
    );
    try {
      const res = await userRequest.get(
        'report/credit-party.php', { params }
      );
      return res;
    } catch (error) { }
  }

  editcontra = async (voucherId, formData) => {
    // console.log("voucher_id", voucherId);

    try {
      const res = await userRequest.put(`contra.php?voucher_id=${voucherId}`, formData)
      return res;
    } catch (error) {
      return error
    }
  }

  getlogreport = async (fromDate, toDate, modulename, refNo, doneBy) => {
  
  const params = {}
  if (fromDate) params.from_date = fromDate
  if (toDate) params.to_date = toDate
  if (modulename) params.module = modulename
  if (refNo) params.ref_no = refNo
  if (doneBy) params.done_by = doneBy

    try {
      const res = await userRequest.get(
        `log-report.php`, { params }
      );
      return res;
    } catch (error) {
      return error;
    }
  };

  //Journal
  getjournal = async (voucherNo, fromDate, toDate, size, page) => {

    const params = {
      voucher_no: voucherNo,
      from_date: fromDate,
      to_date: toDate,
      length: size,
      page: page,
    }

    // if (voucherNo == null) {
    //   voucherNo = "";
    // }

    try {
      const res = await userRequest.get('journal.php', { params })
      return res;
    } catch (error) {
      return error
    }
  }

  refreshmodulelogreport = async () => {
    try {
      const res = await userRequest.post(`log-report.php?mode=options`);
      return res;
    } catch (error) {
      return error;
    }
  };

  getModuleList = async () => {
    try {
      const res = await userRequest.get(`log-report.php?mode=options`);
      return res;
    } catch (error) {
      return error;
    }
  };

  getlogs = async (logId) => {
    try {
      const res = await userRequest.get(
        `log-report.php?log_id=${logId}&mode=retrieve`
      );
      return res;
    } catch (error) {
      return error;
    }
  };

  //Shipment Tracking

  getshipmenttracking = async (
    fromDate,
    toDate,
    senderBranch,
    receiverBranch,
    freight,
    senderName,
    senderMobileNo,
    receiverName,
    receiverMobileNo,
    LrNo,
    status,
    doneBy,
    vehicleNo,
    routeId
  ) => {
    const params = {
      from_date: fromDate,
      to_date: toDate,
      sender_branch: senderBranch,
      receiver_branch: receiverBranch,
      freight: freight,
      sender_name: senderName,
      sender_mobile_no: senderMobileNo,
      receiver_name: receiverName,
      receiver_mobile_no: receiverMobileNo,
      lr_no: LrNo,
      status: status,
      done_by: doneBy,
      vehicle_no: vehicleNo,
      route_id: routeId
    }

    Object.keys(params).forEach(
      (key) => params[key] === undefined || params[key] === "" && delete params[key]
    );

    try {
      const res = await userRequest.get(
        'report/shipment-tracking.php', { params }
      );
      return res;
    } catch (error) {
      return error;
    }

    // console.log('Selected fromDate:', fromDate);
    // console.log('Selected toDate:', toDate);
    // console.log('Selected senderBranch:', senderBranch);

    // try {
    //   const res = await userRequest.get(`shipment-tracking.php?from_date=${fromDate}&to_date=${toDate}&sender_branch=${senderBranch}`)
    //   return res;
    // } catch (error) {
    //   return error
    // }
  };

  //Xlxs
  getshipmenttrackingxlxs = async (
    fromDate,
    toDate,
    senderBranch,
    receiverBranch,
    freight,
    senderName,
    senderMobileNo,
    receiverName,
    receiverMobileNo,
    LrNo,
    status,
    doneBy,
    vehicleNo,
    routeId
  ) => {
    if (
      senderBranch == null &&
      receiverBranch == null &&
      freight == null &&
      senderName == null &&
      senderMobileNo == null &&
      receiverName == null &&
      receiverMobileNo == null &&
      LrNo == null &&
      status == null &&
      doneBy == null &&
      vehicleNo == null &&
      routeId == null
    ) {
      senderBranch = "";
      receiverBranch = "";
      freight = "";
      senderName = "";
      senderMobileNo = "";
      receiverName = "";
      receiverMobileNo = "";
      LrNo = "";
      status = "";
      doneBy = "";
      vehicleNo = "";
      routeId = "";
    }
  }

  //Ledger Report 
  getledgerreport = async (ledgerId, fromDate, toDate) => {

    const params = {
      ledger_id: ledgerId,
      from_date: fromDate,
      to_date: toDate,
    }

    try {
      const res = await userRequest.get('report/ledger_report.php', { params });
      return res;
    } catch (error) {
      return error
    }
  };

  //pdf
  getshipmenttrackingpdf = async (
    fromDate,
    toDate,
    senderBranch,
    receiverBranch,
    freight,
    senderName,
    senderMobileNo,
    receiverName,
    receiverMobileNo,
    LrNo,
    status,
    doneBy,
    vehicleNo,
    routeId
  ) => {
    if (
      senderBranch == null &&
      receiverBranch == null &&
      freight == null &&
      senderName == null &&
      senderMobileNo == null &&
      receiverName == null &&
      receiverMobileNo == null &&
      LrNo == null &&
      status == null &&
      doneBy == null &&
      vehicleNo == null &&
      routeId == null
    ) {
      senderBranch = "";
      receiverBranch = "";
      freight = "";
      senderName = "";
      senderMobileNo = "";
      receiverName = "";
      receiverMobileNo = "";
      LrNo = "";
      status = "";
      doneBy = "";
      vehicleNo = "";
      routeId = "";
    }

    try {
      const res =
        await userRequest.get(`pdf/shipment-tracking-pdf.php?from_date=${fromDate}&to_date=${toDate}&sender_branch=${senderBranch}&
    receiver_branch=${receiverBranch}&freight=${freight}&sender_name=${senderName}&sender_mobile_no=${senderMobileNo}&receiver_name=${receiverName}&
    receiver_mobile_no=${receiverMobileNo}&lr_no=${LrNo}&status=${status}&done_by=${doneBy}&vehicle_no=${vehicleNo}&route_id=${routeId}`);
      return res;
    } catch (error) {
      return error;
    }
  };

  //Route
  getroute1 = async () => {
    try {
      const res = await userRequest.get(`route.php?status=1`);
      return res;
    } catch (error) {
      return error;
    }
  };

  //Branch Total

  getbranchtotalreport = async (
    fromDate,
    toDate,
    senderBranch,
    receiverBranch,
    freight
  ) => {
    const params = {};

    if (fromDate) params.from_date = fromDate;
    if (toDate) params.to_date = toDate;
    if (senderBranch) params.sender_branch = senderBranch;
    if (receiverBranch) params.receiver_branch = receiverBranch;
    if (freight) params.freight = freight;


    try {
      const res = await userRequest.get(`report/branch-total.php`, { params });
      return res;
    } catch (error) {
      return error;
    }
  };

  //Shipping Revenue

  shippingrevenuereport = async (
    lrNo,
    fromDate,
    toDate,
    senderBranch,
    receiverBranch,
    freight,
    senderName,
    senderMobileNo,
    receiverName,
    receiverMobileNo,
    status,
    doneBy,
    vehicleNo,
    routeId
  ) => {
    const params = {
      lr_no: lrNo,
      from_date: fromDate,
      to_date: toDate,
      sender_branch: senderBranch,
      receiver_branch: receiverBranch,
      freight: freight,
      sender_name: senderName,
      sender_mobile_no: senderMobileNo,
      receiver_name: receiverName,
      receiver_mobile_no: receiverMobileNo,
      status: status,
      done_by: doneBy,
      vehicle_no: vehicleNo,
      route_id: routeId
    }

    Object.keys(params).forEach(
      (key) => params[key] === undefined || params[key] === "" && delete params[key]
    );

    try {
      const res =
        await userRequest.get('report/shipping-revenue.php', { params });
      return res;
    } catch (error) {
      return error;
    }
  };

  //Shipping Revenue Sender Branch Based link

  getshippingrevenuesenderbranch = async (fromDate, toDate, senderBranch) => {
    try {
      const res = await userRequest.get(
        `report/shipping-revenue.php?from_date=${fromDate}&to_date=${toDate}&sender_branch=${senderBranch}`
      );
      return res;
    } catch (error) {
      return error;
    }
  };

  //Excel Groups and Ledger

  getgroupsandledger = async (options) => {
    // console.log('option', options);

    try {
      const res = await userRequest.get(
        `csv/accounts_group.php?option=${options}`
      );
      return res;
    } catch (error) {
      return error;
    }
  };

  //Opening Balance

  getopeningbalance = async () => {
    try {
      const res = await userRequest.get(`csv/opening_balance.php`);
      return res;
    } catch (error) {
      return error;
    }
  };

  //Accounts transaction

  getaccounttransaction = async (fromDate, toDate, voucherType) => {
    try {
      const res = await userRequest.get(
        `csv/accounts_transaction.php?from_date=${fromDate}&to_date=${toDate}&voucher_type=${voucherType}`
      );
      return res;
    } catch (error) {
      return error;
    }
  };

  //Sales

  getsales = async (fromDate, toDate) => {
    try {
      const res = await userRequest.get(
        `csv/sales.php?from_date=${fromDate}&to_date=${toDate}`
      );
      return res;
    } catch (error) {
      return error;
    }
  };

  //purchase

  getpurchaseexcel = async (fromDate, toDate) => {
    try {
      const res = await userRequest.get(
        `csv/purchase.php?from_date=${fromDate}&to_date=${toDate}`
      );
      return res;
    } catch (error) {
      return error;
    }
  };

  //Maintenance Tyre Fitment / Removal
  getmaintainenance = async (
    fromDate,
    toDate,
    vehicleNo,
    serialNo,
    currentFitment,
    size,
    page
  ) => {
    fromDate = fromDate ?? "";
    toDate = toDate ?? "";
    vehicleNo = vehicleNo ?? "";
    serialNo = serialNo ?? "";
    currentFitment = currentFitment ?? "";

    try {
      const res = await userRequest.get(
        `tyre_fitment.php?from_date=${fromDate}&to_date=${toDate}&vehicle_no=${vehicleNo}&serial_no=${serialNo}&current_fitment=${currentFitment}&length=${size}&page=${page}`
      );
      return res;
    } catch (error) {
      return error;
    }
  };

  tyreremoval = async (fitementId) => {
    try {
      const res = await userRequest.get(
        `tyre_removal.php?fitment_id=${fitementId}&mode=retrieve`
      );
      return res;
    } catch (error) {
      return error;
    }
  };

  tyreremoval1 = async (fitementId) => {
    try {
      const res = await userRequest.get(
        `tyre_removal.php?removal_id=${fitementId}&mode=retrieve`
      );
      return res;
    } catch (error) {
      return error;
    }
  };

  // Balence Sheet
  getBalenceSheet = async (f_date, t_date) => {
    if (f_date == null) {
      f_date = "";
    }
    if (t_date == null) {
      t_date = "";
    }
    try {
      const res = await userRequest.get(
        `report/balance-sheet.php?from_date=${f_date}&to_date=${t_date}`
      );
      return res;
    } catch (error) {
      return error;
    }
  };

  //Closing Balance
  getClosingBalance = async (id, date) => {
    const params = {
      date: date,
      parent_id: id,
    }

    Object.keys(params).forEach((key) => params[key] === undefined && delete params[key]);

    try {
      const res = await userRequest.get('report/closing-balance.php', { params });

      return res;
    } catch (error) {
      return error;
    }
  };

  // Trial Balaence
  getTrailBalence = async (date) => {
    try {
      const res = await userRequest.get(
        `report/trial-balance.php?date=${date}`
      );
      return res;
    } catch (error) {
      return error;
    }
  };

  //get dispatch list from local mock data
  getdispatchlistmocking = async (
    lrNo,
    fromDate,
    toDate,
    branchId,
    scheduleNo,
    vehicleNo,
    size,
    page
  ) => {
    const params = {};

    if (lrNo) params.lr_no = lrNo;
    if (fromDate) params.from_date = fromDate;
    if (toDate) params.to_date = toDate;
    if (branchId) params.branch_id = branchId;
    if (scheduleNo) params.schedule_no = scheduleNo;
    if (vehicleNo) params.vehicle_no = vehicleNo;
    if (size) params.length = size;
    if (page) params.page = page;

    try {
      const res = await userRequest.get(`dispatch.php`, { params });
      return res;
    } catch (error) {
      return error;
    }
  };

  //edit dispatch
  editdispatch = async (dispatchId, data) => {
    try {
      const res = await userRequest.put(
        `dispatch.php?dispatch_id=${dispatchId}`,
        data
      );
      return res;
    } catch (error) {
      return error;
    }
  };

  //get single dispatch object based on dispatch_id
  getdispatch = async (dispatchId) => {
    try {
      const res = await userRequest.get(
        `dispatch.php?dispatch_id=${dispatchId}&mode=retrieve`
      );
      return res;
    } catch (error) {
      const res = error.response;
    }
  };

  deletedispatchmocking = async (dispatchId) => {
    try {
      const res = await userRequest.delete(
        `dispatch.php?dispatch_id=${dispatchId}`
      );
      return res;
    } catch (error) {
      return error;
    }
  };

  //get dispatch pending details
  getdispatchpendingdetails = async (
    senderBranchId,
    receiverBranchId,
    scheduleId
  ) => {
    if (senderBranchId == null) {
      senderBranchId = "";
    }
    if (receiverBranchId == null) {
      receiverBranchId = "";
    }
    if (scheduleId == null) {
      scheduleId = "";
    }
    try {
      const res = await userRequest.get(
        // `dispatch_pending.php?branch_id=${senderBranchId}`
        `dispatch_pending.php?branch_id=${senderBranchId}&receiver_branch=${receiverBranchId}&schedule_id=${scheduleId}`
      );
      return res;
    } catch (error) {
      return error;
    }
  };

  //add dispath to
  addDispatch = async (data) => {
    try {
      const res = await userRequest.post(`dispatch.php`, data);
      return res;
    } catch (error) {
      return error;
    }
  };

  tyreremovaladd = async (data) => {
    try {
      const res = await userRequest.post(`tyre_removal.php`, data);
      return res;
    } catch (error) {
      return error;
    }
  };

  tyreremovaledit = async (removalId, data) => {
    try {
      const res = await userRequest.put(
        `tyre_removal.php?removal_id=${removalId}`, 
        data
      );
      return res;
    } catch (error) {
      return error;
    }
  };

  //TyreList

  gettyrelist = async () => {
    try {
      const res = await userRequest.get(`search.php?mode=tyre`);
      return res;
    } catch (error) {
      return error;
    }
  };

  //vehicleList

  getvehiclelist = async () => {
    try {
      const res = await userRequest.get(`vehicle_master.php?status=1`);
      return res;
    } catch (error) {
      return error;
    }
  };

  //Tyre unused

  gettyreunused = async (date) => {
    try {
      const res = await userRequest.get(`tyre_unused.php?date=${date}`);
      return res;
    } catch (error) {
      return error;
    }
  };

  addlifeodometerclosing = async (data) => {
    try {
      const res = await userRequest.post(`life_odometer_closing.php`, data);
      // console.log('datatataattatatatat', data)
      return res;
    } catch (error) {
      return error;
    }
  };

  addlifeodometer = async (data) => {
    try {
      const res = await userRequest.post(`life_odometer.php`, data);
      // console.log('datatataattatatatat', data)
      return res;
    } catch (error) {
      return error;
    }
  };


  getlifeodometer =   async (
    fromDate,
    toDate,
    vehicleNo,
    serialNo,
    size,
    page
  ) => {
    fromDate = fromDate ?? "";
    toDate = toDate ?? "";
    vehicleNo = vehicleNo ?? "";
    serialNo = serialNo ?? "";
    try {
      const res = await userRequest.get(
        `life_odometer.php?from_date=${fromDate}&to_date=${toDate}&vehicle_no=${vehicleNo}&serial_no=${serialNo}&length=${size}&page=${page}`
      );
      return res;
    } catch (error) {
      return error;
    }
  };

  viewlifeodometer = async (LifeOdometerId) => {
    try {
      const res = await userRequest.get(
        `life_odometer.php?id=${LifeOdometerId}&mode=retrieve`
      );
      return res;
    } catch (error) {
      return error;
    }
  };

  updatelifeodometer = async (LifeOdometerId, data) => {
    try {
      const res = await userRequest.put(
        `life_odometer.php?id=${LifeOdometerId}`,
        data
      );
      return res;
    } catch (error) {
      return error;
    }
  };
  updateclosinglifeodometer = async (LifeOdometerId, data) => {
    try {
      const res = await userRequest.put(
        `life_odometer.php?life_odometer_id=${LifeOdometerId}`,
        data
      );
      return res;
    } catch (error) {
      return error;
    }
  };

  deletelifeodometerremoval = async (id) => {
    try {
      const res = await userRequest.delete(
        `life_odometer.php?id=${id}`
      );
      return res;
    } catch (error) {
      return error;
    }
  };
  
  
  lifeodometer = async (id) => {
    try {
      const res = await userRequest.get(
        `life_odometer.php?id=${id}&mode=retrieve`
      );
      return res;
    } catch (error) {
      return error;
    }
  };

  addtyrefitement = async (data) => {
    try {
      const res = await userRequest.post(`tyre_fitment.php`, data);
      // console.log('datatataattatatatat', data)
      return res;
    } catch (error) {
      return error;
    }
  };

  viewtyrefitment = async (fitmentId) => {
    try {
      const res = await userRequest.get(
        `tyre_fitment.php?fitment_id=${fitmentId}&mode=retrieve`
      );
      return res;
    } catch (error) {
      return error;
    }
  };

  updatetyrefitment = async (fitmentId, data) => {
    try {
      const res = await userRequest.put(
        `tyre_fitment.php?fitment_id=${fitmentId}`,
        data
      );
      return res;
    } catch (error) {
      return error;
    }
  };

  //getTyreSerialNumber
  gettyreserialnumber = async () => {
    try {
      const res = await userRequest.get(`search.php?mode=tyre`);
      return res;
    } catch (error) {
      return error;
    }
  };

  // Tyre Purchase
  gettyrepurchase = async (billNo, fromDate, toDate, tyreSerialNo, size, page) => {
    const params = {
      bill_no: billNo,
      from_date: fromDate,
      to_date: toDate,
      tyre_serial_no: tyreSerialNo,
      length: size,
      page
    }
    Object.keys(params).forEach((key) => params[key] == undefined || null || "" && delete params[key])

    try {
      const res = await userRequest.get(`tyre_master.php`, { params })
      return res;
    } catch (error) {
      return error
    }
  }

  addtyrepurchase = async (data) => {
    try {
      const res = await userRequest.post(`tyre_master.php`, data);
      return res;
    } catch (error) {
      return error;
    }
  };

  viewtyrepurchase = async (tyreId) => {
    try {
      const res = await userRequest.get(
        `tyre_master.php?id=${tyreId}&mode=retrieve`
      );
      return res;
    } catch (error) {
      return error;
    }
  };

  updatetyrepurchase = async (tyreId, data) => {
    try {
      const res = await userRequest.put(`tyre_master.php?id=${tyreId}`, data);
      return res;
    } catch (error) {
      return error;
    }
  };

  deletetyrepurchase = async (Id) => {
    try {
      const res = await userRequest.delete(`tyre_master.php?id=${Id}`);
      return res;
    } catch (error) {
      return error;
    }
  };

  //pary vendor

  getpartyvendor = async () => {
    try {
      const res = await userRequest.get(`party.php?status=1&type=1`);
      return res;
    } catch (error) {
      return error;
    }
  };

  //schedule Km

  getschedulekm = async (vehicleId, date) => {
    try {
      const res = await userRequest.get(
        `schedule-km.php?vehicle_id=${vehicleId}&date=${date}`
      );
      return res;
    } catch (error) {
      return error;
    }
  };
  getschedulekmdate = async (vehicleId, date) => {
    try {
      const res = await userRequest.get(
        `schedule_km_date.php?vehicle_id=${vehicleId}&date=${date}`
      );
      return res;
    } catch (error) {
      return error;
    }
  };

  //vehicle

  getvehiclemasterlist = async (vehicleId) => {
    try {
      const res = await userRequest.get(
        `vehicle_master.php?vehicle_id=${vehicleId}&mode=retrieve`
      );
      return res;
    } catch (error) {
      return error;
    }
  };

  deletetyrefitmentremoval = async (fitmentId) => {
    try {
      const res = await userRequest.delete(
        `tyre_fitment.php?fitment_id=${fitmentId}`
      );
      return res;
    } catch (error) {
      return error;
    }
  };

  //Tyre Retreated
  gettyreretreated = async (
    fromDate,
    toDate,
    branchId,
    serialNo,
    size,
    page
  ) => {
    fromDate = fromDate ?? "";
    toDate = toDate ?? "";
    branchId = branchId ?? "";
    serialNo = serialNo ?? "";

    try {
      const res = await userRequest.get(
        `tyre_retread.php?from_date=${fromDate}&to_date=${toDate}&branch_id=${branchId}&serial_no=${serialNo}&length=${size}&page=${page}`
      );
      return res;
    } catch (error) {
      return error;
    }
  };

  gettyremaintenancelist = async (branchId) => {
    try {
      const res = await userRequest.get(
        `tyre_maintenance.php?freeze=0&pagination=false&branch_id=${branchId}`
      );
      return res;
    } catch (error) {
      return error;
    }
  };

  addtyreretreated = async (data) => {
    try {
      const res = await userRequest.post(`tyre_retread.php`, data);
      return res;
    } catch (error) {
      return error;
    }
  };

  viewtyreretreated = async (id) => {
    try {
      const res = await userRequest.get(
        `tyre_retread.php?id=${id}&mode=retrieve`
      );
      return res;
    } catch (error) {
      return error;
    }
  };

  updatetyreretreated = async (id, formData) => {
    try {
      const res = await userRequest.put(`tyre_retread.php?id=${id}`, formData);
      return res;
    } catch (error) {
      return error;
    }
  };

  deletetyreretreated = async (id) => {
    try {
      const res = await userRequest.delete(`tyre_retread.php?id=${id}`);
      return res;
    } catch (error) {
      return error;
    }
  };

  //Tyre Dispose
  gettyredisposed = async (fromDate, toDate, branchId, size, page) => {
    fromDate = fromDate ?? "";
    toDate = toDate ?? "";
    branchId = branchId ?? "";

    try {
      const res = await userRequest.get(
        `tyre_dispose.php?from_date=${fromDate}&to_date=${toDate}&branch_id=${branchId}&length=${size}&page=${page}`
      );
      return res;
    } catch (error) {
      return error;
    }
  };

  addtyredisposed = async (data) => {
    try {
      const res = await userRequest.post(`tyre_dispose.php`, data);
      return res;
    } catch (error) {
      return error;
    }
  };

  deletetyredisposed = async (id) => {
    try {
      const res = await userRequest.delete(`tyre_dispose.php?id=${id}`);
      return res;
    } catch (error) {
      return error;
    }
  };

  viewtyredispose = async (id) => {
    try {
      const res = await userRequest.get(
        `tyre_dispose.php?id=${id}&mode=retrieve`
      );
      return res;
    } catch (error) {
      return error;
    }
  };

  updatetyredisposed = async (id, formData) => {
    try {
      const res = await userRequest.put(`tyre_dispose.php?id=${id}`, formData);
      // console.log('formdata', formData)
      return res;
    } catch (error) {
      return error;
    }
  };

  //Tyre Maintenance
  gettyremaintenance = async (
    fromDate,
    toDate,
    branchId,
    serialNo,
    size,
    page
  ) => {
    if (serialNo == null) {
      serialNo = "";
    }
    if (branchId == null) {
      branchId = "";
    }

    try {
      const res = await userRequest.get(
        `tyre_maintenance.php?from_date=${fromDate}&to_date=${toDate}&branch_id=${branchId}&serial_no=${serialNo}&length=${size}&page=${page}`
      );
      return res;
    } catch (error) {
      return error;
    }
  };

  addtyremaintenance = async (data) => {
    try {
      const res = await userRequest.post(`tyre_maintenance.php`, data);
      return res;
    } catch (error) {
      return error;
    }
  };

  //Tyre Removal For

  gettyreremovalfor = async (branchId, removalFor) => {
    try {
      const res = await userRequest.get(
        `/tyre_removal_list.php?branch_id=${branchId}&removal_for=${removalFor}`
      );
      return res;
    } catch (error) {
      return error;
    }
  };

  deletetyremaintenance = async (id) => {
    try {
      const res = await userRequest.delete(
        `tyre_maintenance.php?tyre_maintenance_id=${id}`
      );
      return res;
    } catch (error) {
      return error;
    }
  };

  viewtyremaintenance = async (maintenanceId) => {
    try {
      const res = await userRequest.get(
        `tyre_maintenance.php?tyre_maintenance_id=${maintenanceId}&mode=retrieve`
      );
      return res;
    } catch (error) {
      return error;
    }
  };

  edittyremaintenance = async (tyreMaintenanceId, data) => {
    try {
      const res = await userRequest.put(
        `tyre_maintenance.php?tyre_maintenance_id=${tyreMaintenanceId}`,
        data
      );
      return res;
    } catch (error) {
      return error;
    }
  };

  //luggage cancellation

  viewluggagecancellation = async (luggageId) => {
    try {
      const res = await userRequest.get(
        `luggage-cancellation.php?luggage_id=${luggageId}&mode=retrieve`
      );
      return res;
    } catch (error) {
      return error;
    }
  };

  //luggage missing

  
  viewluggagemissing = async (luggageId) => {
    try {
      const res = await userRequest.get(
        `luggage-missing.php?luggage_id=${luggageId}&mode=retrieve`
      );
      return res;
    } catch (error) {
      return error;
    }
  };

  viewluggagebookingdetails = async (luggageId) => {

    try {
      const res = await userRequest.get(
        `luggage_booking.php?luggage_id=${luggageId}&mode=retrieve`
      );
      return res;
    } catch (error) {
      return error;
    }
  };

  LrNoSearch = async (lrNo) => {

    try {
      const res = await userRequest.get(
        `luggage_booking.php?lr_no=${lrNo}&mode=retrieve`
      );
      return res;
    } catch (error) {
      return error;
    }
  };

  //Driver/Cleaner

  getSingledriver = async (userId) => {
    try {
      const res = await userRequest.get(
        `driver-cleaner.php?user_id=${userId}&mode=retrieve`
      );

      return res;
    } catch (error) {
      return error;
    }
  };

  deleteDriver = async (userId) => {
    try {
      const res = await userRequest.delete(
        `driver-cleaner.php?user_id=${userId}`
      );
      return res;
    } catch (error) {
      return error;
    }
  };

  //adding new driver
  addDriver = async (data) => {
    try {
      console.log('request', data);
      const res = await userRequest.post(`driver-cleaner.php`, data);
      return res;
    } catch (error) {
      return error;
    }
  };

  updateDriver = async (userId, data) => {
    try {
      const res = await userRequest.post(
        `driver-cleaner.php?user_id=${userId}`,
        data
      );
      return res;
    } catch (error) {
      return error;
    }
  };

  //luggage Reformation

  getluggagereformation = async (lrNo) => {
    try {
      const res = await userRequest.get(
        `luggage-reformation.php?lr_no=${lrNo}`
      );
      return res;
    } catch (error) {
      return error;
    }
  };

  updateluggagereformation = async (luggageId, data) => {
    try {
      const res = await userRequest.post(
        `luggage-reformation.php?luggage_id=${luggageId}`,
        data
      );
      return res;
    } catch (error) {
      return error;
    }
  };

  //inventory Asset
  getasset = async (billNo, fromDate, toDate, partyName, size, page) => {
    const params = {
      bill_no: billNo,
      from_date: fromDate,
      to_date: toDate,
      party_name: partyName,
      length: size,
      page: page,
    };
    Object.keys(params).forEach(
      (key) => params[key] == undefined || null || ("" && delete params[key])
    );
    try {
      const res = await userRequest.get(`asset.php`, { params });
      return res;
    } catch (error) {
      return error;
    }
  };

  //pending Asset
  getpendingasset = async () => {
    try {
      const res = await userRequest.get(`pending_assets.php`);
      return res;
    } catch (error) {
      return error;
    }
  };

  //Asset Purchase

  getassetpurchase = async (PurchaseId) => {
    try {
      const res = await userRequest.get(
        `asset_purchase.php?purchase_id=${PurchaseId}`
      );
      return res;
    } catch (error) {
      return error;
    }
  };

  addasset = async (data) => {
    try {
      const res = await userRequest.post(`asset.php`, data);
      return res;
    } catch (error) {
      return error;
    }
  };

  deleteasset = async (id) => {
    try {
      const res = await userRequest.delete(`asset.php?id=${id}`);
      return res;
    } catch (error) {
      return error;
    }
  };

  viewasset = async (id) => {
    try {
      const res = await userRequest.get(`asset.php?id=${id}&mode=retrieve`);
      return res;
    } catch (error) {
      return error;
    }
  };

  updateasset = async (id, formdata) => {
    try {
      const res = await userRequest.put(`asset.php?id=${id}`, formdata);
      return res;
    } catch (error) {
      return error;
    }
  };

  //Purchase
  getinventorypurchase = async (
    billNo,
    fromDate,
    toDate,
    partyName,
    size,
    page
  ) => {
    billNo = billNo ?? "";
    fromDate = fromDate ?? "";
    toDate = toDate ?? "";
    partyName = partyName ?? "";

    try {
      const res = await userRequest.get(
        `purchase.php?bill_no=${billNo}&from_date=${fromDate}&to_date=${toDate}&party_name=${partyName}&length=${size}&page=${page}`
      );
      return res;
    } catch (error) {
      return error;
    }
  };

  getpartyset = async (partyId) => {
    try {
      const res = await userRequest.get(
        `party.php?party_id=${partyId}&mode=retrieve`
      );
      return res;
    } catch (error) {
      return error;
    }
  };

  //add data (schedule -> schedule closing)
  addscheduleclosing = async (values) => {
    try {
      const res = await userRequest.post(`schedule_closing.php`, values);
      return res;
    } catch (error) {
      return error;
    }
  };

  getproductlist = async () => {
    try {
      const res = await userRequest.get(`product_list.php`);
      return res;
    } catch (error) {
      return error;
    }
  };

  //Tax List

  gettaxlist = async () => {
    try {
      const res = await userRequest.get(`tax.php?status=1`);
      return res;
    } catch (error) {
      return error;
    }
  };

  addinventorypurchase = async (data) => {
    try {
      const res = await userRequest.post(`purchase.php`, data);
      return res;
    } catch (error) {
      return error;
    }
  };

  viewinventorypurchase = async (purchaseId) => {
    try {
      const res = await userRequest.get(
        `purchase.php?purchase_id=${purchaseId}&mode=retrieve`
      );
      return res;
    } catch (error) {
      return error;
    }
  };

  updateinventorypurchase = async (purchaseId, formData) => {
    try {
      const res = await userRequest.put(
        `purchase.php?purchase_id=${purchaseId}`,
        formData
      );
      return res;
    } catch (error) {
      return error;
    }
  };

  deleteinventorypurchase = async (purchaseId) => {
    try {
      const res = await userRequest.delete(
        `purchase.php?purchase_id=${purchaseId}`
      );
      return res;
    } catch (error) {
      return error;
    }
  };

  //luggage Delivery

  getdeliverylist = async (
    lrNo,
    fromDate,
    toDate,
    branchId,
    freight,
    size,
    page
  ) => {

    const params = {}
    if (lrNo) params.lr_no = lrNo
    if (fromDate) params.from_date = fromDate
    if (toDate) params.to_date = toDate
    if (branchId) params.branch_id = branchId
    if (freight) params.freight = freight
    if (size) params.length = size
    if (page) params.page = page

    try {
      const res = await userRequest.get(
        `delivery.php`, { params }
      );
      return res;
    } catch (error) {
      return error;
    }
  };

  viewDelivery = async (ID) => {
    try {
      const res = await userRequest.get(
        `delivery.php?delivery_id=${ID}&mode=retrieve`
      );
      return res;
    } catch (error) {
      return error;
    }
  }

  adddelivery = async (data) => {
    try {
      const res = await userRequest.post(`delivery.php`, data);
      return res;
    } catch (error) {
      return error;
    }
  };

  getpendingdeliverylist = async (lrNo, branchId, freight) => {
    const params = {}

    if (lrNo) params.lr_no = lrNo
    if (branchId) params.branch_id = branchId
    if (freight) params.freight = freight

    try {
      const res = await userRequest.get(
        `delivery_pending.php`, { params }
      );
      return res;
    } catch (error) {
      return error;
    }
  };

  getdeliverypendingmodal = async (luggageId) => {
    try {
      const res = await userRequest.get(
        `delivery_pending.php?luggage_id=${luggageId}&date=`
      );
      return res;
    } catch (error) {
      return error;
    }
  };

  deleteluggagedelivery = async (deliveryId) => {
    try {
      const res = await userRequest.delete(
        `delivery.php?delivery_id=${deliveryId}`
      );
      return res;
    } catch (error) {
      return error;
    }
  };

  //Tour Closing
  gettourclosing = async (
    bookingBranch,
    tourNo,
    fromDate,
    toDate,
    pendingClosing,
    size,
    page
  ) => {
    const params = {}
    if (bookingBranch) params.booking_branch = bookingBranch
    if (tourNo) params.tour_no = tourNo
    if (fromDate) params.from_date = fromDate
    if (toDate) params.to_date = toDate
    if (pendingClosing) params.pending_closing = pendingClosing
    if (size) params.length = size
    if (page) params.page = page


    try {
      const res = await userRequest.get(
        `tour_closing.php`, { params }
      );
      return res;
    } catch (error) {
      return error;
    }
  };

  gettourdropdowndata = async () => {
    try {
      const res = await userRequest.get(
        `tour_booking.php?freeze=1&is_closed=0&pagination=false`
      );
      return res;
    } catch (error) {
      return error;
    }
  };

  gettourdropdownsetvalues = async (tourId) => {
    try {
      const res = await userRequest.get(
        `tour_booking.php?tour_id=${tourId}&mode=retrieve`
      );
      return res;
    } catch (error) {
      return error;
    }
  };

  addtourclosing = async (data) => {
    try {
      const res = await userRequest.post(`tour_closing.php`, data);
      return res;
    } catch (error) {
      return error;
    }
  };

  // Tyre Purchase 
  gettyrepurchase = async (billNo, fromDate, toDate, tyreSerialNo, size, page) => {
    const params = {
      bill_no: billNo,
      from_date: fromDate,
      to_date: toDate,
      tyre_serial_no: tyreSerialNo,
      length: size,
      page
    }
    Object.keys(params).forEach((key) => params[key] == undefined || null || "" && delete params[key])

    try {
      const res = await userRequest.get(`tyre_master.php`, { params })
      return res;
    } catch (error) {
      return error
    }
  }

  //Tour Schedule Allotment
  getscheduleallotment = async (
    fromDate,
    toDate,
    tourNo,
    BookingBranch,
    size,
    page
  ) => {
    const params = { freeze: 1 }
    if (fromDate) params.from_date = fromDate
    if (toDate) params.to_date = toDate
    if (tourNo) params.tour_no = tourNo
    if (BookingBranch) params.booking_branch = BookingBranch
    if (size) params.length = size
    if (page) params.page = page

    try {
      const res = await userRequest.get(
        `tour-schedule-allotment.php`, { params }
      );
      return res;
    } catch (error) {
      return error;
    }
  };

  //Tour list
  gettourlist = async () => {
    try {
      const res = await userRequest.get(
        `tour_booking.php?pending_schedule=true`
      );
      // console.log('tourlist', res)
      return res;
    } catch (error) {
      return error;
    }
  };

  // for schedule-activate
  scheduleactivate = async (scheduleId) => {
    // console.log('scheduleId', scheduleId)
    try {
      let res;
      if (scheduleId) {
        res = await userRequest.get(
          `schedule_activate.php?schedule_id=${scheduleId}`
        );
      } else {
        res = await userRequest.get(`schedule_activate.php`);
      }
      return res;
    } catch (error) {
      return error;
    }
  };

  // for schedule-cancel
  schedulecancel = async (scheduleId) => {
    // console.log('scheduleId', scheduleId)
    try {
      const res = await userRequest.get(
        `schedule_activate.php?schedule_id=${scheduleId}`
      );

      return res;
    } catch (error) {
      return error;
    }
  };

  // for delete allotment
  deleteallotment = async (scheduleId) => {
    // console.log('scheduleId', scheduleId)
    try {
      const res = await userRequest.delete(
        `schedule.php?schedule_id=${scheduleId}`
      );
      return res;
    } catch (error) {
      return error;
    }
  };

  //add data from mock api (tour -> schedule allotment)
  addscheduleallotment = async (values) => {
    try {
      const res = await userRequest.post(`schedule.php`, values);
      return res;
    } catch (error) {
      return error;
    }
  };

  getsinglefieldvalue = async (scheduleId) => {
    try {
      const res = await userRequest.get(
        `schedule.php?schedule_id=${scheduleId}&mode=retrieve`
      );
      return res;
    } catch (error) {
      return error;
    }
  };

  addadvanceschedule = async (data) => {
    try {
      const res = await userRequest.post(`schedule-advance.php`, data);
      return res;
    } catch (err) {
      return err;
    }
  };

  editscheduleallotment = async (scheduleId, formData) => {
    try {
      const res = await userRequest.put(
        `schedule.php?schedule_id=${scheduleId}`,
        formData
      );
      return res;
    } catch (error) {
      return error;
    }
  };

  //edit schedule closing (schedule)
  editscheduleclosing = async (Id, values1) => {
    // console.log('id',Id);
    try {
      const res = await userRequest.put(
        `schedule_closing.php?id=${Id}`,
        values1
      );
      return res;
    } catch (error) {
      return error;
    }
  };

  //Unload

  //get unload list from local mock data
  getunloadlistmocking = async (
    fromDate,
    toDate,
    size,
    page,
    lrNo,
    branch,
    scheduleNo,
    vehicle
  ) => {

    const params = {}
    if (fromDate) params.from_date = fromDate
    if (toDate) params.to_date = toDate
    if (size) params.length = size
    if (page) params.page = page
    if (branch) params.branch_id = branch
    if (scheduleNo) params.schedule_no = scheduleNo
    if (vehicle) params.vehicle_no = vehicle
    if (lrNo) params.lr_no = lrNo

    try {
      const res = await userRequest.get(
        `unload.php`, { params }
      );
      return res;
    } catch (error) {
      return error;
    }
  };

  //add data from mock api
  addUnload = async (data) => {
    try {
      const res = await userRequest.post(`unload.php`, data);
      return res;
    } catch (error) {
      return error;
    }
  };

  //get unload (for add feature) pending schedules mock data from api
  getunloadpendingschedule = async (branchId) => {
    branchId = branchId ?? "";
    try {
      const res = await userRequest.get(
        `schedule-unload.php?branch_id=${branchId}`
      );
      return res;
    } catch (error) {
      return error;
    }
  };

  //get unload pending details
  getunloadpendingdetails = async (branchId, scheduleId, luggageId, date) => {
    branchId = branchId ?? "";
    date = date ?? "";
    scheduleId = scheduleId ?? "";
    luggageId = luggageId ?? "";

    try {
      const res = await userRequest(
        `unload_pending.php?branch_id=${branchId}&schedule_id=${scheduleId}`
      );
      return res;
    } catch (error) {
      return error;
    }
  };

  //think its unload pending list
  getunloadpendingmok = async (scheduleId) => {
    scheduleId = scheduleId ?? "";
    try {
      const res = await userRequest.get(
        `checklist.php?schedule_id=${scheduleId}`
      );
      // console.log('taaa', res.data);
      return res;
    } catch (error) {
      return error;
    }
  };

  //think its for unload pending schedule
  getscheduleclosingpendinglist = async () => {
    try {
      const res = await userRequest.get(`schedule-closing-pending-list.php`);
      //  console.log('daaa', res.data);
      return res;
    } catch (error) {
      const res = error.response;
    }
  };

  //save unload Edit
  editunload = async (unloadId, data) => {
    try {
      const res = await userRequest.put(
        `unload.php?unload_id=${unloadId}`,
        data
      );
      return res;
    } catch (error) {
      return error;
    }
  };

  //get unload Edit
  getunload = async (unloadId) => {
    try {
      // console.log('unload', unloadId)
      const res = await userRequest.get(`unload.php?unload_id=${unloadId}`);
      // console.log('aaa', res)
      return res;
    } catch (error) {
      return error;
    }
  };

  viewunload = async (id) => {
    try {
      const res = await userRequest.get(
        `unload.php?unload_id=${id}&mode=retrieve`
      );
      return res;
    } catch (error) {
      return error;
    }
  };

  //delete data from mock api
  deleteunloadmocking = async (unloadId) => {
    try {
      const res = await userRequest.delete(`unload.php?unload_id=${unloadId}`);
      return res;
    } catch (error) {
      return error;
    }
  };

  getpartydropdown = async () => {
    try {
      const res = await userRequest.get(
        `party.php?type=1&category=Fuel-vendar&status=1`
      );
      return res;
    } catch (error) {
      return error;
    }
  };

  viewtourclosing = async (id) => {
    try {
      const res = await userRequest.get(
        `tour_closing.php?id=${id}&mode=retrieve`
      );
      return res;
    } catch (error) {
      return error;
    }
  };

  edittourclosing = async (id, data) => {
    // console.log('id', id)
    try {
      const res = await userRequest.put(`tour_closing.php?id=${id}`, data);
      return res;
    } catch (error) {
      return error;
    }
  };

  //tour Collection
  gettourcollection = async (
    voucherNo,
    branchId,
    tourNo,
    fromDate,
    toDate,
    size,
    page
  ) => {
const params = {}
if(voucherNo) params.voucher_no = voucherNo
if(branchId) params.branch_id = branchId
if(tourNo) params.tour_no = tourNo
if(fromDate) params.from_date = fromDate
if(toDate) params.to_date = toDate
if(size) params.length = size
if(page) params.page = page

    try {
      const res = await userRequest.get(
        `tour_collection.php`, { params }
      );
      return res;
    } catch (error) {
      return error;
    }
  };

  gettourdropdown = async () => {
    try {
      const res = await userRequest.get(
        `tour_booking.php?pagination=false&is_collected=0`
      );
      return res;
    } catch (error) {
      return error;
    }
  };

  //Schedule Batta
  getschedulebatta = async (schNo, vehicleNo, fromDate, toDate, size, page) => {
    const params = {
      from_date: fromDate,
      to_date: toDate,
      sch_no: schNo,
      vehicle_no: vehicleNo,
      length: size,
      page: page,
    }
    Object.keys(params).forEach(
      (key) => params[key] == undefined || null || ("" && delete params[key])
    );

    // if (fromDate) params.from_date = fromDate
    // if (toDate) params.to_date = toDate
    // if (schNo) params.sch_no = schNo
    // if (size) params.length = size
    // if (vehicleNo) params.vehicle_no = vehicleNo
    // if (page) params.page = page

    try {
      const res = await userRequest.get(
        `schedule_batta.php`, { params }
      );
      return res;
    } catch (error) {
      return error;
    }
  };

  getScheduleSelect = async () => {
    const header = {
      headers: {
        Authorization: "Bla|MkxNckpaU0tiUmZ5ajZvSUp2Mzh1QT09|1687441989164",
      },
    };
    try {
      const res = await userRequest.get(`schedule.php?pagination=false`);
      return res;
    } catch (error) {
      return error;
    }
  };

  getscheduleoperator = async (scheduleId) => {
    try {
      const res = await userRequest.get(
        `schedule.php?schedule_id=${scheduleId}&mode=retrieve`
      );
      return res;
    } catch (error) {
      return error;
    }
  };

  addschedulebatta = async (data) => {
    try {
      const res = await userRequest.post(`schedule_batta.php`, data);
      return res;
    } catch (error) {
      return error;
    }
  };

  viewschedulebatta = async (battaId) => {
    try {
      const res = await userRequest.get(
        `schedule_batta.php?batta_id=${battaId}&mode=retrieve`
      );
      return res;
    } catch (error) {
      return error;
    }
  };

  updateschedulebatta = async (battaId, data) => {
    try {
      const res = await userRequest.put(
        `schedule_batta.php?batta_id=${battaId}`,
        data
      );
      return res;
    } catch (error) {
      return error;
    }
  };

  deleteschedulebatta = async (battaId) => {
    try {
      const res = await userRequest.delete(
        `schedule_batta.php?batta_id=${battaId}`
      );
      return res;
    } catch (error) {
      return error;
    }
  };

  addtourcollection = async (data) => {
    try {
      const res = await userRequest.post(`tour_collection.php`, data);
      return res;
    } catch (error) {
      return error;
    }
  };

  viewtourcollection = async (id) => {
    try {
      const res = await userRequest.get(
        `tour_collection.php?id=${id}&mode=retrieve`
      );
      return res;
    } catch (error) {
      return error;
    }
  };

  updatetourcollection = async (id, data) => {
    try {
      const res = await userRequest.put(`tour_collection.php?id=${id}`, data);
      return res;
    } catch (error) {
      return error;
    }
  };

  deletetourcollection = async (id) => {
    try {
      const res = await userRequest.delete(`tour_collection.php?id=${id}`);
      return res;
    } catch (error) {
      return error;
    }
  };

  //schedule Multiple

  addschedulemultiple = async (data) => {
    try {
      const res = await userRequest.post(`schedule_multiple.php`, data);
      return res;
    } catch (error) {
      return error;
    }
  };

  //luggage Cancellation

  getluggagecancellation = async (
    lrNo,
    fromDate,
    toDate,
    senderBranch,
    receiverBranch,
    freight,
    doneBy,
    size,
    page
  ) => {
    const params = {};

    if (lrNo) params.lr_no = lrNo;
    if (fromDate) params.from_date = fromDate;
    if (toDate) params.to_date = toDate;
    if (senderBranch) params.sender_branch = senderBranch;
    if (receiverBranch) params.receiver_branch = receiverBranch;
    if (freight) params.freight = freight;
    if (doneBy) params.done_by = doneBy;
    if (size) params.length = size;
    if (page) params.page = page;

    try {
      const res = await userRequest.get("luggage-cancellation.php", { params });
      return res;
    } catch (error) {
      return error;
    }
  };
//luggage missing
getluggagemissing = async (
  lrNo,
  fromDate,
  toDate,
  senderBranch,
  receiverBranch,
  freight,
  doneBy,
  size,
  page
) => {
  const params = {};

  if (lrNo) params.lr_no = lrNo;
  if (fromDate) params.from_date = fromDate;
  if (toDate) params.to_date = toDate;
  if (senderBranch) params.sender_branch = senderBranch;
  if (receiverBranch) params.receiver_branch = receiverBranch;
  if (freight) params.freight = freight;
  if (doneBy) params.done_by = doneBy;
  if (size) params.length = size;
  if (page) params.page = page;

  try {
    const res = await userRequest.get("luggage-missing.php", { params });
    return res;
  } catch (error) {
    return error;
  }
};
  //luggage booking
  getluggagebooking = async (
    lrNo,
    fromDate,
    toDate,
    senderBranch,
    receiverBranch,
    freight,
    doneBy,
    size,
    page
  ) => {

    const params = {
    }

    if (lrNo) params.lr_no = lrNo
    if (fromDate) params.from_date = fromDate
    if (toDate) params.to_date = toDate
    if (senderBranch) params.sender_branch = senderBranch
    if (receiverBranch) params.receiver_branch = receiverBranch
    if (receiverBranch) params.receiver_branch = receiverBranch
    if (freight) params.freight = freight
    if (doneBy) params.done_by = doneBy
    if (size) params.length = size
    if (page) params.page = page

    // Object.keys(params).forEach((key) => params[key] == undefined || null || "" && delete params[key])

    try {
      const res = await userRequest.get(
        `luggage_booking.php`, { params }
      );
      return res;
    } catch (error) {
      return error;
    }
  };

  getlrno = async (date, doneBy) => {
    try {
      const res = await userRequest.get(
        `luggage_booking.php?mode=get-no&date=${date}&done_by=${doneBy}`
      );
      return res;
    } catch (error) {
      return error;
    }
  };

  addluggagebooking = async (data) => {
    try {
      const res = await userRequest.post(`luggage_booking.php`, data);
      return res;
    } catch (error) {
      return error;
    }
  };

  updateluggagebooking = async (luggageId, data) => {
    try {
      const res = await userRequest.put(
        `luggage_booking.php?luggage_id=${luggageId}`,
        data
      );
      return res;
    } catch (error) {
      return error;
    }
  };
  updatereceiverbooking = async (luggageId, data) => {
    try {
      const res = await userRequest.put(
        `luggage_booking_transfer.php?luggage_id=${luggageId}`,
        data
      );
      return res;
    } catch (error) {
      return error;
    }
  };


  getproducttype = async () => {
    try {
      const res = await userRequest.get(`product.php?product_type=2&status=1`);
      return res;
    } catch (error) {
      return error;
    }
  };

  getbookingprefetch = async (mobileNo, name) => {
    const params = {
      mobile_no: mobileNo,
      name: name,
    }
    try {
      const res = await userRequest.get(
        `luggage_booking_prefetch.php?mobile_no=${mobileNo}&name=${name}&mode=name`
      );
      return res;
    } catch (error) {
      return error;
    }
  };


  viewluggagebooking = async (luggageId) => {
    try {
      const res = await userRequest.get(
        `luggage_booking.php?luggage_id=${luggageId}&mode=retrieve`
      );
      return res;
    } catch (error) {
      return error;
    }
  };

  deleteLuggage = async (luggageId) => {
    try {
      const res = await userRequest.delete(
        `luggage_booking.php?luggage_id=${luggageId}`
      );
      return res;
    } catch (error) {
      return error;
    }
  };

  getluggageprint = async (luggageId) => {
    try {
      const res = await userRequest.get(
        `print/lr-print.php?luggage_id=${luggageId}`
      );
      return res;
    } catch (error) {
      return error;
    }
  };

  luggagecancellation = async (data) => {
    try {
      const res = await userRequest.post(`luggage-cancellation.php`, data);
      return res;
    } catch (error) {
      return error;
    }
  };
  
  luggagemissing = async (data) => {
    try {
      const res = await userRequest.post(`luggage-missing.php`, data);
      return res;
    } catch (error) {
      return error;
    }
  };

  deleteluggagecancelled = async (luggageId) => {
    try {
      const res = await userRequest.delete(
        `luggage-cancellation.php?luggage_id=${luggageId}`
      );
      return res;
    } catch (err) {
      return err;
    }
  };
  
  deleteluggagemissed = async (luggageId) => {
    try {
      const res = await userRequest.delete(
        `luggage-missing.php?luggage_id=${luggageId}`
      );
      return res;
    } catch (err) {
      return err;
    }
  };

  //
  // getluggage = async (scheduleId) => {
  //   try {
  //     const res = await userRequest.get(
  //       `luggage-transfer.php?schedule_id=${scheduleId}`
  //     );
  //     return res;
  //   } catch (error) {
  //     return error;
  //   }
  // };
  getluggage = async (scheduleId) => {
    try {
      const res = await userRequest.get(
        `luggage-transfer.php?schedule_id=${scheduleId}`
      );
      return res;
    } catch (error) {
      return error;
    }
  };

  luggagetransfer = async (data) => {
    try {
      const res = await userRequest.post(`luggage-transfer.php`, data);
      return res;
    } catch (error) {
      return error;
    }
  };

  //ledger mapping

  getledgermapping = async (userId) => {
    try {
      const res = await userRequest.get(`ledger_mapping.php?user_id=${userId}`);
      return res;
    } catch (error) {
      return error;
    }
  };

  addledgermapping = async (data) => {
    try {
      const res = await userRequest.post(`ledger_mapping.php`, data);
      return res;
    } catch (error) {
      return error;
    }
  };

  //Tour Booking
  gettourbooking = async (
    freeze,
    bookingBranch,
    tourNo,
    fromDate,
    toDate,
    fromBoardingTime,
    toBoardingTime,
    size,
    page
  ) => {
const params = {}

if(freeze) params.freeze = freeze;
if(bookingBranch) params.booking_branch = bookingBranch;
if(tourNo) params.tour_no = tourNo;
if(fromDate) params.from_date = fromDate;
if(toDate) params.to_date = toDate;
if(fromBoardingTime) params.from_boarding_time = fromBoardingTime;
if(toBoardingTime) params.to_boarding_time = toBoardingTime;
if(size) params.length = size;
if(page) params.page = page;


    try {
      const res = await userRequest.get(
        `tour_booking.php`, {params}
      );
      return res;
    } catch (error) {
      return error;
    }
  };

  gettourprint = async (tourId) => {
    try {
      const res = await userRequest.get(
        `print/tour-print.php?tour_id=${tourId}`
      );
      return res;
    } catch (error) {
      return error;
    }
  };

  deletetourbooking = async (tourId) => {
    try {
      const res = await userRequest.delete(
        `tour_booking.php?tour_id=${tourId}`
      );
      return res;
    } catch (error) {
      return error;
    }
  };

  deletetourclosing = async (tourId) => {
    try {
      const res = await userRequest.delete(
        `tour_closing.php?id=${tourId}`
      );
      return res;
    } catch (error) {
      return error;
    }
  };

  addtourbooking = async (data) => {
    try {
      const res = await userRequest.post(`tour_booking.php`, data);
      return res;
    } catch (error) {
      return error;
    }
  };

  viewtourbooking = async (tourId) => {
    try {
      const res = await userRequest.get(
        `tour_booking.php?tour_id=${tourId}&mode=retrieve`
      );
      return res;
    } catch (error) {
      return error;
    }
  };

  updatetourbooking = async (data, tourId) => {
    // console.log("tour", tourId)
    try {
      const res = await userRequest.put(
        `tour_booking.php?tour_id=${tourId}`,
        data
      );
      return res;
    } catch (error) {
      return error;
    }
  };
  updatetouradvance = async (data, tourId) => {
    // console.log("tour", tourId)
    try {
      const res = await userRequest.post(
        `tour_booking.php?tour_id=${tourId}`,
        data
      );
      return res;
    } catch (error) {
      return error;
    }
  };

  //profit & loss
  getProfitAndLoss = async (from_date, to_date) => {
    // const params = {
    //   from_date: from_date,
    //   to_date: to_date,
    //   parent_id: parentId,
    // }
    // Object.keys(params).forEach(
    //   (key) => params[key] === undefined && delete params[key]
    // );
    try {
      const res = await userRequest.get(`report/profit_and_loss_report.php?from_date=${from_date}&to_date=${to_date}&parent_id=0`);
      return res;
    } catch (error) {
      const res = error.response;
    }
  };

  getProfitAndLoss1 = async (parentId, from_date, to_date) => {
    try {
      const res = await userRequest.get(`report/profit_and_loss_report.php?parent_id=${parentId}&from_date=${from_date}&to_date=${to_date}`);
      return res;
    } catch (error) {
      const res = error.response;
    }
  };

  getLedgerData3 = async (id, from_date, to_date, ob) => {
    try {
      const res = await userRequest.get(
        `report/ledger-brief.php?ledger_id=${id}&from_date=${from_date}&to_date=${to_date}&parent_id=0`
      );
      return res;
    } catch (error) {
      const res = error.response;
    }
  };

  getLedgerData = async (id, from_date, to_date, ob) => {
    try {
      const res = await userRequest.get(
        `report/ledger-brief.php?ledger_id=${id}&from_date=${from_date}&to_date=${to_date}&ob=${ob}`
      );
      return res;
    } catch (error) {
      const res = error.response;
    }
  };
  getLedgerData1 = async (id, from_date, to_date) => {
    try {
      const res = await userRequest.get(
        `report/ledger-brief.php?ledger_id=${id}&from_date=${from_date}&to_date=${to_date}`
      );
      return res;
    } catch (error) {
      const res = error.response;
    }
  };

  //Access Settings
  getmenu = async () => {
    try {
      const res = await userRequest.get(`menu.php`);
      return res;
    } catch (error) {
      const res = error.response;
    }
  };

  getmodule = async () => {
    try {
      const res = await userRequest.get(`menu-master.php?parent_id=0`);
      return res;
    } catch (error) {
      return error;
    }
  };

  getmoduledata = async (parentId) => {
    try {
      const res = await userRequest.get(
        `menu-master.php?parent_id=${parentId}`
      );
      return res;
    } catch (error) {
      return error;
    }
  };

  getaccesssetting = async (userId, roleId) => {
    try {
      let res;
      if (roleId) {
        res = await userRequest.get(`access_settings.php?role_id=${roleId}`);
      }
      if (userId) {
        res = await userRequest.get(`access_settings.php?user_id=${userId}`);
      }
      return res;
    } catch (error) {
      return error;
    }
  };

  getaccesssettingpath =  async (path) => {
    try {
      const res = await userRequest.get(`access_settings.php?path=${path}`);
      return res;
    } catch (error) {
      return error;
    }
  };

  updateaccesssetting = async (data) => {
    try {
      console.log("hello",data);
      const res = await userRequest.post(`access_settings.php`, data);
      return res;
    } catch (error) {
      return error;
    }
  };

  //Maintenance

  getmaintenance = async (
    fromDate,
    toDate,
    vehicleNo,
    maintenanceId,
    size,
    page
  ) => {
    fromDate = fromDate ?? "";
    toDate = toDate ?? "";
    vehicleNo = vehicleNo ?? "";
    maintenanceId = maintenanceId ?? "";

    try {
      const res = await userRequest.get(
        `maintenance.php?from_date=${fromDate}&to_date=${toDate}&vehicle_no=${vehicleNo}&maintenance_id=${maintenanceId}&length=${size}&page=${page}`
      );
      return res;
    } catch (error) {
      return error;
    }
  };

  getmaintenancehistory = async (maintenanceId) => {
    try {
      const res = await userRequest.get(
        `maintenance-history.php?maintenance_id=${maintenanceId}&mode=history`
      );
      return res;
    } catch (error) {
      return error;
    }
  };

  getvehicleHistory = async (vehicleId, maintenanceId) => {
    try {
      const res = await userRequest.get(
        `maintenance-history.php?vehicle_id=${vehicleId}&maintenance_id=${maintenanceId}&mode=history`
      );
      return res;
    } catch (error) {
      return error;
    }
  };

  getvehiclekm = async (vehicleId, date) => {
    try {
      const res = await userRequest.get(
        `schedule-km.php?vehicle_id=${vehicleId}&date=`
      );
      return res;
    } catch (error) {
      return error;
    }
  };

  getassetstock = async () => {
    try {
      const res = await userRequest.get(`asset_stock.php`);
      return res;
    } catch (error) {
      return error;
    }
  };

  addmaitenance = async (data) => {
    try {
      const res = await userRequest.post(`maintenance.php`, data);
      return res;
    } catch (error) {
      return error;
    }
  };

  deletemaintenance = async (Id) => {
    try {
      const res = await userRequest.delete(`maintenance.php?id=${Id}`);
      return res;
    } catch (error) {
      return error;
    }
  };

  //get branch day booking
  getbranchdaybooking = async (BranchId, FromDate, ToDate, Condition) => {
    const params = {
      branch_id: BranchId,
      from_date: FromDate,
      to_date: ToDate,
      Condition
    }

    if (!params.branch_id) {
      delete params["branch_id"]
    }
    if (!params.from_date) {
      delete params["from_date"]
    }
    if (!params.to_date) {
      delete params["to_date"]
    }
    if (!params.Condition) {
      delete params[Condition]
    }

    try {
      const res = await userRequest.get('report/branch_day_book.php', { params });
      return res;
    } catch (error) {
      return error;
    }
  };

  //schedule -> schedule closing
  getscheduleclosing = async (
    VehicleNo,
    ScheduleNo,
    ClosingBranch,
    FromDate,
    ToDate,
    size,
    page
  ) => {
    const params = {
      from_date: FromDate,
      to_date: ToDate,
      vehicle_no: VehicleNo,
      sch_no: ScheduleNo,
      branch_id: ClosingBranch,      
      length: size,
      page: page
    }

    Object.keys(params).forEach(
      (key) => params[key] === undefined || params[key] === "" && delete params[key]
    );    

    try {
      const res = await userRequest.get(
        'schedule_closing.php', {params}
      );
      return res;
    } catch (error) {
      return error;
    }
  };

  //Delete schedule closing
  deletescheduleclosing = async (Id) => {
    // console.log('id', Id);
    try {
      const res = await userRequest.delete(`schedule_closing.php?id=${Id}`);
      return res;
    } catch (error) {
      return error;
    }
  };

  //schedule schedule allotment
  getscheduleallotmentschedule = async (
    scheduleNo,
    vehicleNo,
    serviceNo,
    fromDate,
    toDate,
    boardingBranchId,
    depatureBranchId,
    size,
    page
  ) => {
    const params = {
      from_date: fromDate,
      to_date: toDate,
      service_no: serviceNo,
      boarding_branch: boardingBranchId,
      departure_branch: depatureBranchId,
      vehicle_no: vehicleNo,
      sch_no: scheduleNo,
      length: size,
      page: page
    }

    Object.keys(params).forEach(
      (key) => params[key] === undefined || params[key] === "" && delete params[key]
    );

    try {
      const res = await userRequest.get(
        'schedule.php', {params}
      );
      return res;
    } catch (error) {
      return error;
    }
  };

  addSchedule = async (data) => {
    try {
      const res = await userRequest.post(`schedule.php`, data);
      return res;
    } catch (error) {
      return error;
    }
  };

  //get Schedule Pending
  getScheduleList = async () => {
    try {
      const res = await userRequest.get(`schedule-closing-pending-list.php`);
      return res;
    } catch (error) {
      return error;
    }
  };

  //get Supplier List
  getSupplierList = async () => {
    try {
      const res = await userRequest.get(
        `party.php?type=1&category=Fuel-Vendar&status=1`
      );
      return res;
    } catch (error) {
      return error;
    }
  };

  //get supplier schedule closing
  getSupplierList_schedule = async () => {
    try {
      const res = await userRequest.get(
        `party.php?type=1&status=1&category=Fuel-Vendar&status=1`
      );
      return res;
    } catch (error) {
      return error;
    }
  };

  //schedule -> schedule closing -> edit
  getsinglefieldvalue1 = async (Id) => {
    try {
      const res = await userRequest.get(
        `schedule_closing.php?id=${Id}&mode=retrieve`
      );
      return res;
    } catch (error) {
      return error;
    }
  };

  getsinglechedule = async (scheduleId) => {
    try {
      const res = await userRequest.get(
        `schedule.php?schedule_id=${scheduleId}&mode=retrieve`
      );
      return res;
    } catch (error) {
      return error;
    }
  };

  deletesingleschedule = async (scheduleId) => {
    try {
      const res = await userRequest.delete(
        `schedule.php?schedule_id=${scheduleId}$&mode=retrieve`
      );
      return res;
    } catch (error) {
      return error;
    }
  };

  updateschedule = async (scheduleId, data) => {
    try {
      const res = await userRequest.put(
        `schedule.php?schedule_id=${scheduleId}`,
        data
      );
      return res;
    } catch (error) {
      return error;
    }
  };
}


export default API;
