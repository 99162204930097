import React, {useState, useLayoutEffect, lazy, Suspense, useEffect, useRef} from "react";
import {
  LoginOutlined,
  KeyOutlined,
  MoreOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  Dropdown,
  Space,
  Layout,
  theme,
  Spin,
  Modal,
  Form,
  Input,
  Button,
  Row,
  Col,
  message,
} from "antd";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Switch,
  useLocation,
  Link,
  Navigate,
} from "react-router-dom";

import styled from "styled-components";
// import logo from "../Assets/Images/logo.png";
import API from "../Store/Api/ApiService";
import {useDispatch} from "react-redux";
import {ADMIN_LOGOUT} from "../Store/Contans/AdminContans";
import LedgerMapping from "../Component/Master/LedgerMapping";
const {Search} = Input;

//Component Lazy Loading..
const Login = lazy(() => import("../Component/Login"));
const Login1 = lazy(() => import("../Component/Loginwithotp"))
const MenuBar = lazy(() => import("../Component/MenuBar"));
const MenuBarNew = lazy(() => import("../Component/MenuBarNew"));
const MobileMenu = lazy(() => import("../Component/MobileMenu"));
const Home = lazy(() => import("../Component/Home"));
const State = lazy(() => import("../Component/Pre-requisities/state"));
const District = lazy(() => import("../Component/Pre-requisities/District"));
const Company = lazy(() => import("../Component/Pre-requisities/Company"));
const Area = lazy(() => import("../Component/Pre-requisities/Area"));
const Branch = lazy(() => import("../Component/Pre-requisities/Branch"));
const Demurrage = lazy(() => import("../Component/Pre-requisities/Demurrage"));
const Role = lazy(() => import("../Component/Master/Role"));
const User = lazy(() => import("../Component/Master/User"));
const UserAdd = lazy(() => import("../Component/Master/UserAdd"));
const UserEdit = lazy(() => import("../Component/Master/UserEdit"));
const VehicleType = lazy(() => import("../Component/Master/VehicleType"));
const MaintenanceMaster = lazy(() =>
  import("../Component/Master/MaintenanceMaster")
);
const Vehicle = lazy(() => import("../Component/Master/Vehicle"));
const GST = lazy(() => import("../Component/Master/GST"));
const Category = lazy(() => import("../Component/Master/Category"));
const VehicleDocument = lazy(() =>
  import("../Component/Master/VehicleDocument")
);
const CustomerVendar = lazy(() => import("../Component/Master/CustomerVendar"));
const Product = lazy(() => import("../Component/Master/Product"));
const PurchaseProduct = lazy(() =>
  import("../Component/Master/PurchaseProduct")
);
const Attachment = lazy(() => import("../Component/Master/Attachment"));
const Group = lazy(() => import("../Component/Accounts/Group"));
const Ledger = lazy(() => import("../Component/Accounts/Ledger"));
const Receipt = lazy(() => import("../Component/Accounts/Receipt"));
const Contra = lazy(() => import("../Component/Accounts/Contra"));
const Journal = lazy(() => import("../Component/Accounts/Journal"));
// const ReceiptNew = lazy (() => import("..Component/ReceiptNew"))
const Payment = lazy(() => import("../Component/Accounts/Payment"));
const LedgerReport = lazy(() => import("../Component/Accounts/LedgerReport"));
const LogReportView = lazy(() => import("../Component/Report/LogReportView"));
const BranchDayBook = lazy(() => import("../Component/Accounts/BranchDayBook"));
const RouteMenu = lazy(() => import("../Component/Schedule/Route"));
const ScheduleAllotment = lazy(() =>
  import("../Component/Schedule/ScheduleAllotment")
);
const ActiveSchedule = lazy(() =>
  import("../Component/Schedule/ActiveSchedule")
);
const ScheduleClosing = lazy(() =>
  import("../Component/Schedule/ScheduleClosing")
);
const ScheduleMultiple = lazy(() =>
  import("../Component/Schedule/ScheduleMultiple")
);
const LuggageDemurrage = lazy(() => import("../Component/Luggage/Demurrage"));
const Reformation = lazy(() => import("../Component/Luggage/Reformation"));
const Booking = lazy(() => import("../Component/Luggage/Booking"));
const BookingAdd = lazy(() => import("../Component/Luggage/BookingAdd"));
const BookingEdit = lazy(() => import("../Component/Luggage/BookingEdit"));
const BookingView = lazy(() => import("../Component/Luggage/BookingView"));
const BookingViewDetail = lazy(() => import("../Component/Luggage/BookingViewDetail"));
const BookingTransfer = lazy(() => import("../Component/Luggage/BookingTransfer"));
const Dispatch = lazy(() => import("../Component/Luggage/Dispatch"));
const DispatchAdd = lazy(() => import("../Component/Luggage/DispatchAdd"));
const Unload = lazy(() => import("../Component/Luggage/Unload"));
const UnloadAdd = lazy(() => import("../Component/Luggage/UnloadAdd"));
const UnloadPending = lazy(() => import("../Component/Luggage/UnloadPending"));
const Delivery = lazy(() => import("../Component/Luggage/Delivery"));
const DeliveryAdd = lazy(() => import("../Component/Luggage/DeliveryAdd"));
const Transfer = lazy(() => import("../Component/Luggage/Transfer"));
const Cancelled = lazy(() => import("../Component/Luggage/Cancelled"));
const Missing = lazy(() => import("../Component/Luggage/Missing"));
const TourBooking = lazy(() => import("../Component/Tour/Booking"));
const TourScheduleAllotment = lazy(() =>
  import("../Component/Tour/ScheduleAllotment")
);
const TourClosing = lazy(() => import("../Component/Tour/Closing"));
const TourCollection = lazy(() => import("../Component/Tour/Collection"));
const Purchase = lazy(() => import("../Component/Inventory/Purchase"));
const PurchaseAdd = lazy(() => import("../Component/Inventory/PurchaseAdd"));
const PurchaseEdit = lazy(() => import("../Component/Inventory/PurchaseEdit"));
const Asset = lazy(() => import("../Component/Inventory/Asset"));
const TyrePurchase = lazy(() => import("../Component/Inventory/TyrePurchase"));
const TyrePurchaseAdd = lazy(() =>
  import("../Component/Inventory/TyrePurchaseAdd")
);
const TyrePurchaseEdit = lazy(() =>
  import("../Component/Inventory/TyrePurchaseEdit")
);
const TyreFitmentRemoval = lazy(() =>
  import("../Component/Maintenance/TyreFitmentRemoval")
);
const Maintenance = lazy(() => import("../Component/Maintenance/Maintenance"));
const MaintenanceAdd = lazy(() =>
  import("../Component/Maintenance/MaintenanceAdd")
);
const TyreMaintenance = lazy(() =>
  import("../Component/Maintenance/TyreMaintenance")
);
const TyreRetreated = lazy(() =>
  import("../Component/Maintenance/TyreRetreated")
);
const TyreDispose = lazy(() => import("../Component/Maintenance/TyreDispose"));
const PurchaseReport = lazy(() => import("../Component/Report/Purchase"));
const TyreSummaryReport = lazy(() => import("../Component/Report/TyreSummary"));
const MaintenanceReport = lazy(() => import("../Component/Report/Maintenance"));
const LifeOdometer = lazy(() => import("../Component/Odometer/LifeOdometer"));
const ShipmentTracking = lazy(() =>
  import("../Component/Report/ShipmentTracking")
);
const Schedule = lazy(() => import("../Component/Report/Schedule"));
const ShippingRevenue = lazy(() =>
  import("../Component/Report/ShippingRevenue")
);
const TourReport = lazy(() => import("../Component/Report/Tour"));
const FuelReport = lazy(() => import("../Component/Report/Fuel"));
const CreditPartyReport = lazy(() => import("../Component/Report/CreditParty"));
const BranchTotalReport = lazy(() => import("../Component/Report/BranchTotal"));
const LogReport = lazy(() => import("../Component/Report/LogReport"));
const ExpenseAndIncomeReport = lazy(() =>
  import("../Component/Report/ExpenseAndIncome")
);
const LuggageReport = lazy(() => import("../Component/Report/Luggage"));
const GroupsAndLedgers = lazy(() =>
  import("../Component/ExcelDownload-Tally/GroupsAndLedgers")
);
const OpeningBalance = lazy(() =>
  import("../Component/ExcelDownload-Tally/OpeningBalance")
);
const AccountsTransaction = lazy(() =>
  import("../Component/ExcelDownload-Tally/AccountsTransaction")
);
const Sales = lazy(() => import("../Component/ExcelDownload-Tally/Sales"));
const PurchaseExcel = lazy(() =>
  import("../Component/ExcelDownload-Tally/Purchase")
);
const AccessSettings = lazy(() => import("../Component/Master/AccessSettings"));
const Ledgermapping = lazy(() => import("../Component/Master/LedgerMapping"));
const DriverCleaner = lazy(() => import("../Component/Master/DriverCleaner"));
const DriverCleanerAdd = lazy(() =>
  import("../Component/Master/DriverCleanerAdd")
);
const DriverCleanerEdit = lazy(() =>
  import("../Component/Master/DriverCleanerEdit")
);
const ScheduleBatta = lazy(() => import("../Component/Schedule/ScheduleBatta"));
const ReceiptAdd = lazy(() => import("../Component/Accounts/ReceiptAdd"));
const PaymentAdd = lazy(() => import("../Component/Accounts/PaymentAdd"));
const JournalAdd = lazy(() => import("../Component/Accounts/JournalAdd"));
const JournalEdit = lazy(() => import("../Component/Accounts/JournalEdit"));
const BalanceSheet = lazy(() => import("../Component/Accounts/BalanceSheet"));
const ClosingBalance = lazy(() =>
  import("../Component/Accounts/ClosingBalance")
);
const ProfitAndLoss = lazy(() => import("../Component/Accounts/ProfitAndLoss"));
const TrailBalance = lazy(() => import("../Component/Accounts/TrialBalence"));


// const Home = lazy(() => import("../Component/Home"));

const {Header, Content, Footer} = Layout;

const Dashboard = () => {
  const [resetPasswordFrom] = Form.useForm();
  const [resetPasswordModelOpen, setresetPasswordModelOpen] = useState(false);
  const [searchData, setSearchData] = useState([]);
  const [lastActiveTime, setLastActiveTime] = useState(Date.now());

  const lastActiveTimeRef = useRef(Date.now());

  useEffect(() => {
    const checkInactivity = () => {
      console.log('Checking for Inactivity..!');
      const currentTime = Date.now();
      const timeElapsed = currentTime - lastActiveTimeRef.current;
      if (timeElapsed >= 3600000) {
        logout();
      }
    };

    const inactivityInterval = setInterval(checkInactivity, 30000);

    const resetInactivityTimer = () => {
      lastActiveTimeRef.current = Date.now();
    };

    document.addEventListener('mousemove', resetInactivityTimer);
    document.addEventListener('keydown', resetInactivityTimer);
    document.addEventListener('mousedown', resetInactivityTimer);

    return () => {
      clearInterval(inactivityInterval);
      document.removeEventListener('mousemove', resetInactivityTimer);
      document.removeEventListener('keydown', resetInactivityTimer);
      document.removeEventListener('mousedown', resetInactivityTimer);
    };
  }, []); 

  const user = JSON.parse(localStorage.getItem("persist:root"))?.admin;
  const isAuthenticated = user && JSON.parse(user).isAuthenticated;
  const userData = user ? JSON.parse(user)?.user : {};
  console.log(userData);
  const d = new Date();
  const dispatch = useDispatch();
  const {
    token: {colorBgContainer},
  } = theme.useToken();
  const api = new API();
  const Wrapper = ({children}) => {
    const location = useLocation();
    useLayoutEffect(() => {
      document.documentElement.scrollTo(500, 0);
    }, [location.pathname]);
    return children;
  };

  const logout = () => {
    dispatch({type: ADMIN_LOGOUT});
    window.location.reload();
    window.location.href = "/";
  };

  //reset password
  const getPassword = () => {
    api
      .getpassword()
      .then((res) => {
        if (res?.status == 200) {
          resetPasswordFrom.setFieldValue("password", res?.data?.password);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleClickResetPasssword = () => {
    setresetPasswordModelOpen(true);
    getPassword();
  };

  const handleResetPasswordModalClose = () => {
    setresetPasswordModelOpen(false);
  };

  const handleGenerateClick = () => {
    getPassword();
  };

  const updatePassword = () => {
    const data = {
      password: resetPasswordFrom.getFieldValue("password"),
    };

    api
      .updatePassworduser(data)
      .then((res) => {
        if (res?.data?.success) {
          message.success(res?.data?.message);
          // console.log(res)
          setresetPasswordModelOpen(false);
        } else {
          message.warning(res?.data?.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const items = [
    // {
    //   label: (
    //     <>
    //       <a onClick={handleClickResetPasssword}>
    //         <KeyOutlined /> Change Password
    //       </a>
    //     </>
    //   ),
    //   key: "0",
    // },
    {
      label: (
        <>
          <a onClick={(e) => logout()}>
            <LoginOutlined /> Logout
          </a>
        </>
      ),
      key: "1",
    },
  ];

  const onSearch = (value) => {
    window.location.href = `/luggage/booking/view/lr/${value}`;
  };

  return (
    <React.Fragment>
      <Router>
        <Modal
          title="Reset Password"
          open={resetPasswordModelOpen}
          // onOk={handleOk}
          onCancel={handleResetPasswordModalClose}
          width={400}
          footer={false}
        >
          <Form
            name="reset password"
            style={{width: "80%"}}
            form={resetPasswordFrom}
          >
            <Form.Item name="password">
              <Input placeholder="password" />
            </Form.Item>
            <Row align="" justify="center">
              <Col span={12}>
                <Form.Item>
                  <Button type="primary" onClick={updatePassword}>
                    Save
                  </Button>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item>
                  <Button
                    onClick={handleGenerateClick}
                    type="text"
                    style={{color: "blue"}}
                  >
                    Generate
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
        <Suspense
          fallback={
            <div className="suspense_wrap">
              <Spin tip="Loading" size="small" />
            </div>
          }
        >
          {isAuthenticated === true ? (
            <DashboardSection>
              <Layout
                style={{
                  minHeight: "100vh",
                }}
              >
                <MenuBar />
                <Layout className="site-layout">
                  <Header
                    style={{
                      padding: 0,
                      background: colorBgContainer,
                      position: "fixed",
                      top: 0,
                      left: 0,
                      width: "100%",
                      zIndex: 1000,
                    }}
                  >
                    <div className="header_align">
                      <div className="header_left">
                        <div className="header_left_menu">
                          <MobileMenu />
                        </div>
                        <Link to="/home">
                          <h2 className="logo_name_h2">BOS-Travels</h2>
                        </Link>
                      </div>
                      <div className="header_middle">
                        <Input.Search
                          placeholder="LR No"
                          allowClear
                          enterButton="Search"
                          size="medium"
                          onSearch={onSearch}

                          // style={{background: "blue", color: "white"}}
                        />
                      </div>
                      <div className="header_right">
                        <Dropdown
                          menu={{
                            items,
                          }}
                          trigger={["click"]}
                        >
                          <a onClick={(e) => e.preventDefault()}>
                            <Space>
                              {userData?.data?.name}
                              <MoreOutlined />
                            </Space>
                          </a>
                        </Dropdown>
                      </div>
                    </div>
                  </Header>
                  <Wrapper>
                    <Content
                      style={{
                        margin: "0 16px",
                        padding: "80px 0px 50px 0px",
                      }}
                    >
                      <Routes>
                        <Route
                          path="/home"
                          element={
                            <div className="suspense_wrap_com">
                              <Suspense fallback={<Spin />}>
                                <Home />
                              </Suspense>
                            </div>
                          }
                          exact
                        />
                      </Routes>

                      <Routes>
                        <Route
                          path="/prerequisites/state"
                          element={
                            <div className="suspense_wrap_com">
                              <Suspense fallback={<Spin />}>
                                <State />
                              </Suspense>
                            </div>
                          }
                          exact
                        />
                      </Routes>
                      <Routes>
                        <Route
                          path="/prerequisites/district"
                          element={
                            <div className="suspense_wrap_com">
                              <Suspense fallback={<Spin />}>
                                <District />
                              </Suspense>
                            </div>
                          }
                          exact
                        />
                      </Routes>
                      <Routes>
                        <Route
                          path="/prerequisites/Area"
                          element={
                            <div className="suspense_wrap_com">
                              <Suspense fallback={<Spin />}>
                                <Area />
                              </Suspense>
                            </div>
                          }
                          exact
                        />
                      </Routes>
                      <Routes>
                        <Route
                          path="/prerequisites/company"
                          element={
                            <div className="suspense_wrap_com">
                              <Suspense fallback={<Spin />}>
                                <Company />
                              </Suspense>
                            </div>
                          }
                          exact
                        />
                      </Routes>
                      <Routes>
                        <Route
                          path="/prerequisites/branch"
                          element={
                            <div className="suspense_wrap_com">
                              <Suspense fallback={<Spin />}>
                                <Branch />
                              </Suspense>
                            </div>
                          }
                          exact
                        />
                      </Routes>
                      <Routes>
                        <Route
                          path="/prerequisites/demurrage"
                          element={
                            <div className="suspense_wrap_com">
                              <Suspense fallback={<Spin />}>
                                <Demurrage />
                              </Suspense>
                            </div>
                          }
                          exact
                        />
                      </Routes>
                      <Routes>
                        <Route
                          path="/master/role"
                          element={
                            <div className="suspense_wrap_com">
                              <Suspense fallback={<Spin />}>
                                <Role />
                              </Suspense>
                            </div>
                          }
                          exact
                        />
                      </Routes>
                      <Routes>
                        <Route
                          path="/master/user"
                          element={
                            <div className="suspense_wrap_com">
                              <Suspense fallback={<Spin />}>
                                <User />
                              </Suspense>
                            </div>
                          }
                          exact
                        />
                      </Routes>
                      <Routes>
                        <Route
                          path="/master/user/add"
                          element={
                            <div className="suspense_wrap_com">
                              <Suspense fallback={<Spin />}>
                                <UserAdd />
                              </Suspense>
                            </div>
                          }
                          exact
                        />
                      </Routes>
                      <Routes>
                        <Route
                          path="/master/user/edit/:id"
                          element={
                            <div className="suspense_wrap_com">
                              <Suspense fallback={<Spin />}>
                                <UserEdit />
                              </Suspense>
                            </div>
                          }
                          exact
                        />
                      </Routes>
                      <Routes>
                        <Route
                          path="/master/vehicle-type"
                          element={
                            <div className="suspense_wrap_com">
                              <Suspense fallback={<Spin />}>
                                <VehicleType />
                              </Suspense>
                            </div>
                          }
                          exact
                        />
                      </Routes>
                      <Routes>
                        <Route
                          path="/master/maintenance"
                          element={
                            <div className="suspense_wrap_com">
                              <Suspense fallback={<Spin />}>
                                <MaintenanceMaster />
                              </Suspense>
                            </div>
                          }
                          exact
                        />
                      </Routes>
                      <Routes>
                        <Route
                          path="/master/bus"
                          element={
                            <div className="suspense_wrap_com">
                              <Suspense fallback={<Spin />}>
                                <Vehicle />
                              </Suspense>
                            </div>
                          }
                          exact
                        />
                      </Routes>
                      <Routes>
                        <Route
                          path="/master/party"
                          element={
                            <div className="suspense_wrap_com">
                              <Suspense fallback={<Spin />}>
                                <CustomerVendar />
                              </Suspense>
                            </div>
                          }
                          exact
                        />
                      </Routes>
                      <Routes>
                        <Route
                          path="/master/category"
                          element={
                            <div className="suspense_wrap_com">
                              <Suspense fallback={<Spin />}>
                                <Category />
                              </Suspense>
                            </div>
                          }
                          exact
                        />
                      </Routes>
                      <Routes>
                        <Route
                          path="/master/tax"
                          element={
                            <div className="suspense_wrap_com">
                              <Suspense fallback={<Spin />}>
                                <GST />
                              </Suspense>
                            </div>
                          }
                          exact
                        />
                      </Routes>
                      <Routes>
                        <Route
                          path="/master/vehicle-document"
                          element={
                            <div className="suspense_wrap_com">
                              <Suspense fallback={<Spin />}>
                                <VehicleDocument />
                              </Suspense>
                            </div>
                          }
                          exact
                        />
                      </Routes>
                      <Routes>
                        <Route
                          path="/master/product"
                          element={
                            <div className="suspense_wrap_com">
                              <Suspense fallback={<Spin />}>
                                <Product />
                              </Suspense>
                            </div>
                          }
                          exact
                        />
                      </Routes>
                      <Routes>
                        <Route
                          path="/master/purchaseProduct"
                          element={
                            <div className="suspense_wrap_com">
                              <Suspense fallback={<Spin />}>
                                <PurchaseProduct />
                              </Suspense>
                            </div>
                          }
                          exact
                        />
                      </Routes>
                      <Routes>
                        <Route
                          path="/master/access-settings"
                          element={
                            <div className="suspense_wrap_com">
                              <Suspense fallback={<Spin />}>
                                <AccessSettings />
                              </Suspense>
                            </div>
                          }
                          exact
                        />
                      </Routes>
                      <Routes>
                        <Route
                          path="/master/ledger-mapping"
                          element={
                            <div className="suspense_wrap_com">
                              <Suspense fallback={<Spin />}>
                                <LedgerMapping />
                              </Suspense>
                            </div>
                          }
                          exact
                        />
                      </Routes>
                      <Routes>
                        <Route
                          path="/master/driver-cleaner"
                          element={
                            <div className="suspense_wrap_com">
                              <Suspense fallback={<Spin />}>
                                <DriverCleaner />
                              </Suspense>
                            </div>
                          }
                          exact
                        />
                      </Routes>
                      <Routes>
                        <Route
                          path="/master/driver-cleaner/add"
                          element={
                            <div className="suspense_wrap_com">
                              <Suspense fallback={<Spin />}>
                                <DriverCleanerAdd />
                              </Suspense>
                            </div>
                          }
                          exact
                        />
                      </Routes>
                      <Routes>
                        <Route
                          path="/master/driver-cleaner/edit/:id"
                          element={
                            <div className="suspense_wrap_com">
                              <Suspense fallback={<Spin />}>
                                <DriverCleanerEdit />
                              </Suspense>
                            </div>
                          }
                          exact
                        />
                      </Routes>
                      <Routes>
                        <Route
                          path="/master/attachment"
                          element={
                            <div className="suspense_wrap_com">
                              <Suspense fallback={<Spin />}>
                                <Attachment />
                              </Suspense>
                            </div>
                          }
                          exact
                        />
                      </Routes>
                      <Routes>
                        <Route
                          path="/accounts/group"
                          element={
                            <div className="suspense_wrap_com">
                              <Suspense fallback={<Spin />}>
                                <Group />
                              </Suspense>
                            </div>
                          }
                          exact
                        />
                      </Routes>
                      <Routes>
                        <Route
                          path="/accounts/ledger"
                          element={
                            <div className="suspense_wrap_com">
                              <Suspense fallback={<Spin />}>
                                <Ledger />
                              </Suspense>
                            </div>
                          }
                          exact
                        />
                      </Routes>
                      <Routes>
                        <Route
                          path="/accounts/receipt"
                          element={
                            <div className="suspense_wrap_com">
                              <Suspense fallback={<Spin />}>
                                <Receipt />
                              </Suspense>
                            </div>
                          }
                          exact
                        />
                      </Routes>

                      <Routes>
                        <Route
                          path="/accounts/payment"
                          element={
                            <div className="suspense_wrap_com">
                              <Suspense fallback={<Spin />}>
                                <Payment />
                              </Suspense>
                            </div>
                          }
                          exact
                        />
                      </Routes>
                      <Routes>
                        <Route
                          path="/accounts/payment/add"
                          element={
                            <div className="suspense_wrap_com">
                              <Suspense fallback={<Spin />}>
                                <PaymentAdd />
                              </Suspense>
                            </div>
                          }
                          exact
                        />
                      </Routes>
                      <Routes>
                        <Route
                          path="/accounts/contra"
                          element={
                            <div className="suspense_wrap_com">
                              <Suspense fallback={<Spin />}>
                                <Contra />
                              </Suspense>
                            </div>
                          }
                          exact
                        />
                      </Routes>
                      <Routes>
                        <Route
                          path="/accounts/journal"
                          element={
                            <div className="suspense_wrap_com">
                              <Suspense fallback={<Spin />}>
                                <Journal />
                              </Suspense>
                            </div>
                          }
                          exact
                        />
                      </Routes>
                      <Routes>
                        <Route
                          path="/accounts/journal/add"
                          element={
                            <div className="suspense_wrap_com">
                              <Suspense fallback={<Spin />}>
                                <JournalAdd />
                              </Suspense>
                            </div>
                          }
                          exact
                        />
                      </Routes>
                      <Routes>
                        <Route
                          path="/accounts/journal/edit/:id"
                          element={
                            <div className="suspense_wrap_com">
                              <Suspense fallback={<Spin />}>
                                <JournalEdit />
                              </Suspense>
                            </div>
                          }
                          exact
                        />
                      </Routes>
                      <Routes>
                        <Route
                          path="/accounts/ledger-report"
                          element={
                            <div className="suspense_wrap_com">
                              <Suspense fallback={<Spin />}>
                                <LedgerReport />
                              </Suspense>
                            </div>
                          }
                          exact
                        />
                      </Routes>
                      <Routes>
                        <Route
                          path="/accounts/receipt/add"
                          element={
                            <div className="suspense_wrap_com">
                              <Suspense fallback={<Spin />}>
                                <ReceiptAdd />
                              </Suspense>
                            </div>
                          }
                          exact
                        />
                      </Routes>
                      <Routes>
                        <Route
                          path="/accounts/branch-day-book"
                          element={
                            <div className="suspense_wrap_com">
                              <Suspense fallback={<Spin />}>
                                <BranchDayBook />
                              </Suspense>
                            </div>
                          }
                          exact
                        />
                      </Routes>
                      <Routes>
                        <Route
                          path="/accounts/balance-sheet"
                          element={
                            <div className="suspense_wrap_com">
                              <Suspense fallback={<Spin />}>
                                <BalanceSheet />
                              </Suspense>
                            </div>
                          }
                          exact
                        />
                      </Routes>
                      <Routes>
                        <Route
                          path="/accounts/closing-balance"
                          element={
                            <div className="suspense_wrap_com">
                              <Suspense fallback={<Spin />}>
                                <ClosingBalance />
                              </Suspense>
                            </div>
                          }
                          exact
                        />
                      </Routes>
                      <Routes>
                        <Route
                          path="/accounts/profit-loss"
                          element={
                            <div className="suspense_wrap_com">
                              <Suspense fallback={<Spin />}>
                                <ProfitAndLoss />
                              </Suspense>
                            </div>
                          }
                          exact
                        />
                      </Routes>
                      <Routes>
                        <Route
                          path="/accounts/trial-balance"
                          element={
                            <div className="suspense_wrap_com">
                              <Suspense fallback={<Spin />}>
                                <TrailBalance />
                              </Suspense>
                            </div>
                          }
                          exact
                        />
                      </Routes>
                      <Routes>
                        <Route
                          path="/schedule/route"
                          element={
                            <div className="suspense_wrap_com">
                              <Suspense fallback={<Spin />}>
                                <RouteMenu />
                              </Suspense>
                            </div>
                          }
                          exact
                        />
                      </Routes>
                      <Routes>
                        <Route
                          path="/schedule/regular"
                          element={
                            <div className="suspense_wrap_com">
                              <Suspense fallback={<Spin />}>
                                <ScheduleAllotment />
                              </Suspense>
                            </div>
                          }
                          exact
                        />
                      </Routes>
                      <Routes>
                        <Route
                          path="/schedule/schedule-active"
                          element={
                            <div className="suspense_wrap_com">
                              <Suspense fallback={<Spin />}>
                                <ActiveSchedule />
                              </Suspense>
                            </div>
                          }
                          exact
                        />
                      </Routes>
                      <Routes>
                        <Route
                          path="/schedule/schedule-closing"
                          element={
                            <div className="suspense_wrap_com">
                              <Suspense fallback={<Spin />}>
                                <ScheduleClosing />
                              </Suspense>
                            </div>
                          }
                          exact
                        />
                      </Routes>
                      <Routes>
                        <Route
                          path="/schedule/batta"
                          element={
                            <div className="suspense_wrap_com">
                              <Suspense fallback={<Spin />}>
                                <ScheduleBatta />
                              </Suspense>
                            </div>
                          }
                          exact
                        />
                      </Routes>
                      <Routes>
                        <Route
                          path="/schedule/schedule-multiple"
                          element={
                            <div className="suspense_wrap_com">
                              <Suspense fallback={<Spin />}>
                                <ScheduleMultiple />
                              </Suspense>
                            </div>
                          }
                          exact
                        />
                      </Routes>
                      <Routes>
                        <Route
                          path="/luggage/demurrage"
                          element={
                            <div className="suspense_wrap_com">
                              <Suspense fallback={<Spin />}>
                                <LuggageDemurrage />
                              </Suspense>
                            </div>
                          }
                          exact
                        />
                      </Routes>
                      <Routes>
                        <Route
                          path="/luggage/reformation"
                          element={
                            <div className="suspense_wrap_com">
                              <Suspense fallback={<Spin />}>
                                <Reformation />
                              </Suspense>
                            </div>
                          }
                          exact
                        />
                      </Routes>
                      <Routes>
                        <Route
                          path="/luggage/booking"
                          element={
                            <div className="suspense_wrap_com">
                              <Suspense fallback={<Spin />}>
                                <Booking />
                              </Suspense>
                            </div>
                          }
                          exact
                        />
                      </Routes>
                      <Routes>
                        <Route
                          path="/luggage/booking/add"
                          element={
                            <div className="suspense_wrap_com">
                              <Suspense fallback={<Spin />}>
                                <BookingAdd />
                              </Suspense>
                            </div>
                          }
                          exact
                        />
                      </Routes>
                      <Routes>
                        <Route
                         path="/luggage/booking/view/id/:id"
                          element={
                            <div className="suspense_wrap_com">
                              <Suspense fallback={<Spin />}>
                                <BookingView />
                              </Suspense>
                            </div>
                          }
                          exact
                        />
                        <Route
                        path="/luggage/booking/view/lr/:lrNumber"
                          element={
                            <div className="suspense_wrap_com">
                              <Suspense fallback={<Spin />}>
                                <BookingView />
                              </Suspense>
                            </div>
                          }
                          exact
                        />
                         <Route
                        path="/luggage/booking/view-detail/lr/:lrNumber"
                          element={
                            <div className="suspense_wrap_com">
                              <Suspense fallback={<Spin />}>
                                <BookingViewDetail />
                              </Suspense>
                            </div>
                          }
                          exact
                        />
                        {/* <Route
                          path="/luggage/booking/view/:lrno"
                          element={
                            <div className="suspense_wrap_com">
                              <Suspense fallback={<Spin />}>
                                <BookingView />
                              </Suspense>
                            </div>
                          }
                          exact
                        /> */}
                      </Routes>
                      <Routes>
                        <Route
                          path="/luggage/booking/edit/:id"
                          element={
                            <div className="suspense_wrap_com">
                              <Suspense fallback={<Spin />}>
                                <BookingEdit />
                              </Suspense>
                            </div>
                          }
                          exact
                        />
                      </Routes>
                      <Routes>
                        <Route
                          path="/luggage/booking/transfer/id/:id"
                          element={
                            <div className="suspense_wrap_com">
                              <Suspense fallback={<Spin />}>
                                <BookingTransfer />
                              </Suspense>
                            </div>
                          }
                          exact
                        />
                      </Routes>
                      <Routes>
                        <Route
                          path="/luggage/dispatch"
                          element={
                            <div className="suspense_wrap_com">
                              <Suspense fallback={<Spin />}>
                                <Dispatch />
                              </Suspense>
                            </div>
                          }
                          exact
                        />
                      </Routes>
                      <Routes>
                        <Route
                          path="/luggage/dispatch/add"
                          element={
                            <div className="suspense_wrap_com">
                              <Suspense fallback={<Spin />}>
                                <DispatchAdd />
                              </Suspense>
                            </div>
                          }
                          exact
                        />
                      </Routes>
                      <Routes>
                        <Route
                          path="/luggage/unload"
                          element={
                            <div className="suspense_wrap_com">
                              <Suspense fallback={<Spin />}>
                                <Unload />
                              </Suspense>
                            </div>
                          }
                          exact
                        />
                      </Routes>
                      <Routes>
                        <Route
                          path="/luggage/unload/add"
                          element={
                            <div className="suspense_wrap_com">
                              <Suspense fallback={<Spin />}>
                                <UnloadAdd />
                              </Suspense>
                            </div>
                          }
                          exact
                        />
                      </Routes>
                      <Routes>
                        <Route
                          path="/luggage/unload/pending"
                          element={
                            <div className="suspense_wrap_com">
                              <Suspense fallback={<Spin />}>
                                <UnloadPending />
                              </Suspense>
                            </div>
                          }
                          exact
                        />
                      </Routes>
                      <Routes>
                        <Route
                          path="/luggage/delivery"
                          element={
                            <div className="suspense_wrap_com">
                              <Suspense fallback={<Spin />}>
                                <Delivery />
                              </Suspense>
                            </div>
                          }
                          exact
                        />
                      </Routes>
                      <Routes>
                        <Route
                          path="/luggage/delivery/add"
                          element={
                            <div className="suspense_wrap_com">
                              <Suspense fallback={<Spin />}>
                                <DeliveryAdd />
                              </Suspense>
                            </div>
                          }
                          exact
                        />
                      </Routes>
                      <Routes>
                        <Route
                          path="/luggage/transfer"
                          element={
                            <div className="suspense_wrap_com">
                              <Suspense fallback={<Spin />}>
                                <Transfer />
                              </Suspense>
                            </div>
                          }
                          exact
                        />
                      </Routes>
                      <Routes></Routes>
                      <Routes>
                        <Route
                          path="/luggage/cancelled"
                          element={
                            <div className="suspense_wrap_com">
                              <Suspense fallback={<Spin />}>
                                <Cancelled />
                              </Suspense>
                            </div>
                          }
                          exact
                        />
                      </Routes>
                      <Routes>
                        <Route
                          path="/luggage/missing"
                          element={
                            <div className="suspense_wrap_com">
                              <Suspense fallback={<Spin />}>
                                <Missing />
                              </Suspense>
                            </div>
                          }
                          exact
                        />
                      </Routes>
                      <Routes>
                        <Route
                          path="/tour/booking"
                          element={
                            <div className="suspense_wrap_com">
                              <Suspense fallback={<Spin />}>
                                <TourBooking />
                              </Suspense>
                            </div>
                          }
                          exact
                        />
                      </Routes>
                      <Routes>
                        <Route
                          path="/tour/schedule-allotment"
                          element={
                            <div className="suspense_wrap_com">
                              <Suspense fallback={<Spin />}>
                                <TourScheduleAllotment />
                              </Suspense>
                            </div>
                          }
                          exact
                        />
                      </Routes>
                      <Routes>
                        <Route
                          path="/tour/closing"
                          element={
                            <div className="suspense_wrap_com">
                              <Suspense fallback={<Spin />}>
                                <TourClosing />
                              </Suspense>
                            </div>
                          }
                          exact
                        />
                      </Routes>
                      <Routes>
                        <Route
                          path="/tour/collection"
                          element={
                            <div className="suspense_wrap_com">
                              <Suspense fallback={<Spin />}>
                                <TourCollection />
                              </Suspense>
                            </div>
                          }
                          exact
                        />
                      </Routes>
                      <Routes>
                        <Route
                          path="/inventory/purchase"
                          element={
                            <div className="suspense_wrap_com">
                              <Suspense fallback={<Spin />}>
                                <Purchase />
                              </Suspense>
                            </div>
                          }
                          exact
                        />
                      </Routes>
                      <Routes>
                        <Route
                          path="/inventory/purchase/add"
                          element={
                            <div className="suspense_wrap_com">
                              <Suspense fallback={<Spin />}>
                                <PurchaseAdd />
                              </Suspense>
                            </div>
                          }
                          exact
                        />
                      </Routes>
                      <Routes>
                        <Route
                          path="/inventory/purchase/edit/:id"
                          element={
                            <div className="suspense_wrap_com">
                              <Suspense fallback={<Spin />}>
                                <PurchaseEdit />
                              </Suspense>
                            </div>
                          }
                          exact
                        />
                      </Routes>
                      <Routes>
                        <Route
                          path="/inventory/asset"
                          element={
                            <div className="suspense_wrap_com">
                              <Suspense fallback={<Spin />}>
                                <Asset />
                              </Suspense>
                            </div>
                          }
                          exact
                        />
                      </Routes>
                      <Routes>
                        <Route
                          path="/inventory/tyre"
                          element={
                            <div className="suspense_wrap_com">
                              <Suspense fallback={<Spin />}>
                                <TyrePurchase />
                              </Suspense>
                            </div>
                          }
                          exact
                        />
                      </Routes>
                      <Routes>
                        <Route
                          path="/inventory/tyre/add"
                          element={
                            <div className="suspense_wrap_com">
                              <Suspense fallback={<Spin />}>
                                <TyrePurchaseAdd />
                              </Suspense>
                            </div>
                          }
                          exact
                        />
                      </Routes>
                      <Routes>
                        <Route
                          path="/inventory/tyre/edit/:id"
                          element={
                            <div className="suspense_wrap_com">
                              <Suspense fallback={<Spin />}>
                                <TyrePurchaseEdit />
                              </Suspense>
                            </div>
                          }
                          exact
                        />
                      </Routes>
                      <Routes>
                        <Route
                          path="/maintenance/tyre-fitment"
                          element={
                            <div className="suspense_wrap_com">
                              <Suspense fallback={<Spin />}>
                                <TyreFitmentRemoval />
                              </Suspense>
                            </div>
                          }
                          exact
                        />
                      </Routes>
                      <Routes>
                        <Route
                          path="/maintenance/maintenance"
                          element={
                            <div className="suspense_wrap_com">
                              <Suspense fallback={<Spin />}>
                                <Maintenance />
                              </Suspense>
                            </div>
                          }
                          exact
                        />
                      </Routes>
                      <Routes>
                        <Route
                          path="/maintenance/maintenance/add"
                          element={
                            <div className="suspense_wrap_com">
                              <Suspense fallback={<Spin />}>
                                <MaintenanceAdd />
                              </Suspense>
                            </div>
                          }
                          exact
                        />
                      </Routes>

                      <Routes>
                        <Route
                          path="/maintenance/tyre-maintenance"
                          element={
                            <div className="suspense_wrap_com">
                              <Suspense fallback={<Spin />}>
                                <TyreMaintenance />
                              </Suspense>
                            </div>
                          }
                          exact
                        />
                      </Routes>
                      <Routes>
                        <Route
                          path="/maintenance/tyre-retread"
                          element={
                            <div className="suspense_wrap_com">
                              <Suspense fallback={<Spin />}>
                                <TyreRetreated />
                              </Suspense>
                            </div>
                          }
                          exact
                        />
                      </Routes>
                      <Routes>
                        <Route
                          path="/maintenance/tyre-dispose"
                          element={
                            <div className="suspense_wrap_com">
                              <Suspense fallback={<Spin />}>
                                <TyreDispose />
                              </Suspense>
                            </div>
                          }
                          exact
                        />
                      </Routes>
                      <Routes>
                        <Route
                          path="/report/purchase"
                          element={
                            <div className="suspense_wrap_com">
                              <Suspense fallback={<Spin />}>
                                <PurchaseReport />
                              </Suspense>
                            </div>
                          }
                          exact
                        />
                      </Routes>
                      <Routes>
                        <Route
                          path="/report/tyre-summary"
                          element={
                            <div className="suspense_wrap_com">
                              <Suspense fallback={<Spin />}>
                                <TyreSummaryReport />
                              </Suspense>
                            </div>
                          }
                          exact
                        />
                      </Routes>
                      <Routes>
                        <Route
                          path="/report/maintenance"
                          element={
                            <div className="suspense_wrap_com">
                              <Suspense fallback={<Spin />}>
                                <MaintenanceReport />
                              </Suspense>
                            </div>
                          }
                          exact
                        />
                      </Routes>
                      <Routes>
                        <Route
                          path="/report/shipment-tracking"
                          element={
                            <div className="suspense_wrap_com">
                              <Suspense fallback={<Spin />}>
                                <ShipmentTracking />
                              </Suspense>
                            </div>
                          }
                          exact
                        />
                      </Routes>
                      <Routes>
                        <Route
                          path="/report/Schedule"
                          element={
                            <div className="suspense_wrap_com">
                              <Suspense fallback={<Spin />}>
                                <Schedule />
                              </Suspense>
                            </div>
                          }
                          exact
                        />
                      </Routes>
                      <Routes>
                        <Route
                          path="/report/shipping-revenue"
                          element={
                            <div className="suspense_wrap_com">
                              <Suspense fallback={<Spin />}>
                                <ShippingRevenue />
                              </Suspense>
                            </div>
                          }
                          exact
                        />
                      </Routes>
                      <Routes>
                        <Route
                          path="/report/tour"
                          element={
                            <div className="suspense_wrap_com">
                              <Suspense fallback={<Spin />}>
                                <TourReport />
                              </Suspense>
                            </div>
                          }
                          exact
                        />
                      </Routes>
                      <Routes>
                        <Route
                          path="/report/fuel"
                          element={
                            <div className="suspense_wrap_com">
                              <Suspense fallback={<Spin />}>
                                <FuelReport />
                              </Suspense>
                            </div>
                          }
                          exact
                        />
                      </Routes>
                      <Routes>
                        <Route
                          path="/report/credit-party"
                          element={
                            <div className="suspense_wrap_com">
                              <Suspense fallback={<Spin />}>
                                <CreditPartyReport />
                              </Suspense>
                            </div>
                          }
                          exact
                        />
                      </Routes>
                      <Routes>
                        <Route
                          path="/report/branch-total"
                          element={
                            <div className="suspense_wrap_com">
                              <Suspense fallback={<Spin />}>
                                <BranchTotalReport />
                              </Suspense>
                            </div>
                          }
                          exact
                        />
                      </Routes>
                      <Routes>
                        <Route
                          path="/log-report"
                          element={
                            <div className="suspense_wrap_com">
                              <Suspense fallback={<Spin />}>
                                <LogReport />
                              </Suspense>
                            </div>
                          }
                          exact
                        />
                      </Routes>
                      <Routes>
                        <Route
                          path="/log-report/view/:id"
                          element={
                            <div className="suspense_wrap_com">
                              <Suspense fallback={<Spin />}>
                                <LogReportView />
                              </Suspense>
                            </div>
                          }
                          exact
                        />
                      </Routes>
                      <Routes>
                        <Route
                          path="/report/expense-income"
                          element={
                            <div className="suspense_wrap_com">
                              <Suspense fallback={<Spin />}>
                                <ExpenseAndIncomeReport />
                              </Suspense>
                            </div>
                          }
                          exact
                        />
                      </Routes>
                      <Routes>
                        <Route
                          path="/report/luggage"
                          element={
                            <div className="suspense_wrap_com">
                              <Suspense fallback={<Spin />}>
                                <LuggageReport />
                              </Suspense>
                            </div>
                          }
                          exact
                        />
                      </Routes>
                      <Routes>
                        <Route
                          path="//excel-downloads-tally/groups-and-ledgers"
                          element={
                            <div className="suspense_wrap_com">
                              <Suspense fallback={<Spin />}>
                                <GroupsAndLedgers />
                              </Suspense>
                            </div>
                          }
                          exact
                        />
                      </Routes>
                      <Routes>
                        <Route
                          path="//excel-downloads-tally/opening-balance"
                          element={
                            <div className="suspense_wrap_com">
                              <Suspense fallback={<Spin />}>
                                <OpeningBalance />
                              </Suspense>
                            </div>
                          }
                          exact
                        />
                      </Routes>
                      <Routes>
                        <Route
                          path="//excel-downloads-tally/accounts-transaction"
                          element={
                            <div className="suspense_wrap_com">
                              <Suspense fallback={<Spin />}>
                                <AccountsTransaction />
                              </Suspense>
                            </div>
                          }
                          exact
                        />
                      </Routes>
                      <Routes>
                        <Route
                          path="//excel-downloads-tally/sales"
                          element={
                            <div className="suspense_wrap_com">
                              <Suspense fallback={<Spin />}>
                                <Sales />
                              </Suspense>
                            </div>
                          }
                          exact
                        />
                      </Routes>
                      <Routes>
                        <Route
                          path="//excel-downloads-tally/purchase"
                          element={
                            <div className="suspense_wrap_com">
                              <Suspense fallback={<Spin />}>
                                <PurchaseExcel />
                              </Suspense>
                            </div>
                          }
                          exact
                        />
                      </Routes>
                      <Routes>
                      <Route
                          path="odometer/life-odometer"
                          element={
                            <div className="suspense_wrap_com">
                              <Suspense fallback={<Spin />}>
                               <LifeOdometer />
                              </Suspense>
                            </div>
                          }
                          exact
                        />
                      </Routes>
                    </Content>
                  </Wrapper>
                  <Footer
                    style={{
                      textAlign: "center",
                      fontFamily: "q_bold",
                      fontSize: "13px",
                    }}
                  >
                    ©{d.getFullYear()} Developed by{" "}
                    <a
                      href="https://blazon.in/"
                      title="Blazon"
                      target="_blank"
                      rel="noreferrer"
                      style={{color: "var(--bg)", fontWeight: 600}}
                    >
                      Blazon
                    </a>
                  </Footer>
                </Layout>
              </Layout>
            </DashboardSection>
          ) : (
            <Routes>
              <Route exact path="/" element={<Login1 />} />
            </Routes>
          )}
        </Suspense>
      </Router>
    </React.Fragment>
  );
};

export default Dashboard;

const DashboardSection = styled.section`
  #components-layout-demo-side .logo {
    height: 32px;
    margin: 16px;
    background: rgba(255, 255, 255, 0.3);
  }
  .logo_name_h2 {
    color: #000;
    font-size: 20px;
    font-family: "q_bold";
  }
  .D_Header {
    position: sticky;
    top: 0;
    left: 0px;
    width: 100%;
    z-index: 100;
  }
  .ant-space-item {
    color: var(--bg);
    font-family: "q_bold";
  }
  .site-layout .site-layout-background,
  .ant-layout-sider,
  .ant-menu-dark .ant-menu-sub,
  .ant-menu.ant-menu-dark,
  .ant-menu.ant-menu-dark .ant-menu-sub,
  .ant-layout-sider-trigger {
    background: #fff;
  }
  .logo {
    height: 64px;
    border-bottom: 1px solid #f5f5f5;
    border-right: 1px solid #f5f5f5;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 23px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.2px;
  }

  .LayoutSection {
    min-height: 100vh;
  }
  .ant-layout-header {
    background: #fff;
    padding: 10px 20px 10px 20px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    line-height: normal;
    border-bottom: 1px solid #f5f5f5;
  }
  .ant-layout,
  body {
    background: #f6f9ff;
  }
  .ant-layout-sider-trigger {
    color: #f6f9ff;
    border-top: 1px solid #f2f2f2;
    background: #000;
    z-index: 120;
    height: 40px;
    line-height: 40px;
    overflow: hidden;
  }

  .ant-menu-dark .ant-menu-sub,
  .ant-menu.ant-menu-dark,
  .ant-menu.ant-menu-dark .ant-menu-sub,
  .ant-menu-dark .ant-menu-item,
  .ant-menu-dark .ant-menu-item-group-title,
  .ant-menu-dark .ant-menu-item > a,
  .ant-menu-dark .ant-menu-item > span > a {
    color: #000;
  }

  .ant-layout-footer {
    padding: 12px 15px;
    background: #f6f9ff;
    color: #000;
    text-align: center;
    border-top: 1px solid #f5f5f5;
  }
  .Contents {
    width: 100%;
    display: inline-block;
    position: relative;
    padding: 24px;
    margin: 0px 0 0 0;
  }
  .BreadCrumb {
    margin: 0 0 30px;
  }
  .ant-layout-sider-children {
    position: fixed;
    width: 200px;
    z-index: 101;
    top: 0;
  }
  ul.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-light,
  .ant-layout-sider-children .ant-menu.ant-menu-inline-collapsed {
    border-right: 1px solid #f0f0f0;
    height: 85vh;
    min-height: 85vh;
    max-height: 85vh;
    overflow: hidden;
    overflow-y: auto;
    padding: 0 0 20px;
  }

  /* width */
  ul.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-light::-webkit-scrollbar,
  .ant-layout-sider-children
    .ant-menu.ant-menu-inline-collapsed::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ul.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-light::-webkit-scrollbar-track,
  .ant-layout-sider-children
    .ant-menu.ant-menu-inline-collapsed::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ul.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-light::-webkit-scrollbar-thumb,
  .ant-layout-sider-children
    .ant-menu.ant-menu-inline-collapsed::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
  }

  /* Handle on hover */
  ul.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-light::-webkit-scrollbar-thumb:hover,
  .ant-layout-sider-children
    .ant-menu.ant-menu-inline-collapsed::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-collapsed.ant-layout-sider-has-trigger
    .ant-layout-sider-children {
    width: 60px;
  }
  .ant-menu-item-selected {
    background-color: rgb(245 146 9 / 10%);
    margin: 0 !important;
    width: 100% !important;
    border-radius: 0;
  }

  .ant-menu-item-selected .ant-menu-item-icon svg {
    color: var(--bg);
  }
  .ant-menu-title-content {
    font-size: 13px;
    font-family: "q_bold";
  }
  .ant-menu-item-selected .ant-menu-title-content {
    color: var(--bg);
  }
  .ant-menu-item {
    height: 37px;
    line-height: 37px;
    width: 100% !important;
    border-radius: 0;
    margin: 5px 0 !important;
  }
  .ant-menu-submenu-title {
    margin: 5px 0;
    height: 37px !important;
    line-height: 37px !important;
    width: 100% !important;
    border-radius: 0;
  }
  .ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-collapsed.ant-layout-sider-has-trigger {
    flex: 0 0 60px !important;
    max-width: 60px !important;
    min-width: 60px !important;
    width: 60px !important;
  }
  .ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-collapsed.ant-layout-sider-has-trigger
    .ant-layout-sider-trigger {
    width: 60px !important;
  }
  .ant-menu-sub .ant-menu-title-content {
    font-family: "q_medium" !important;
  }

  .header_align {
    display: flex;
    align-items: center;
    height: 64px;
    padding: 10px 17px;
    justify-content: space-between;
    width: 100%;
    z-index: 1000;
  }
  .header_align .header_left {
    width: fit-content;
    display: flex;
    align-items: center;
    gap: 15px;
  }
  .header_left_menu {
    display: none;
  }
  .header_align .header_left svg {
    font-size: 20px;
    cursor: pointer;
  }
  .header_align .header_right {
    width: fit-content;
    display: inline-block;
  }

  .header_align .header_middle {
    /* background: red; */
    margin-left: 40%;
  }
  .header_align .header_left img {
    height: 35px;
  }
  .header_align .header_right button {
    padding: 2px 8px;
    height: auto;
    font-family: "q_medium";
    background: var(--bg);
    span {
      font-family: "q_medium";
    }
  }
  .header_align .header_right button:hover {
    background: #000;
  }

  /* @media screen and (max-width: 1200px) {
    .header_left_menu {
      display: block;
    }
  } */
`;
